import React from "react";
import Lottie from "lottie-react";

//Assets
import Infinity from "../assets/new-inifnite-450.json"



class InfinitySection extends React.Component {


    render() {


        const infinityScroll = {
            mode: "scroll",
            actions: [
              {
                visibility: [0, 0.1],
                type: "stop",
                frames: [0],
              },
              {
                visibility: [0.2, 0.8],
                type: "seek",
                frames: [0, 450],
              },

            ],
        };
        



        return (

            <div className="container home-infinity">
            <Lottie
            animationData={Infinity}
            interactivity={infinityScroll}
            style={{ width: "80%", height: "80%" }}
            />
            </div>
         );
    }
    
}
 
export default InfinitySection;
