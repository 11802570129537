import React, { Component } from 'react'
import Footer from "../components/footer";

export default class BlogDesignsystem extends Component {
  render() {
    return (
        <div className="blog-temp-parent overflow-hidden v2-blog">
            <style>
                {
                    `.inner-page-btn {
                        display: none;
                    }`
                }
            </style>
            <div className="home-cover-out"></div>
            <div className="container blog-temp-hero">
                <div className="row main-hero-blog-row">
                    <div className="col-lg-5 bth-right-p workanimation">
                        <img alt="Fingerprints Future" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img.png" className="img-fluid" />
                    </div>
                    <div className="col-lg-7 bth-left-p">
                        <div className="bth-left-p-cont">
                            <div className='blog-chips-cover workanimation'>
                                <div className='blog-chip'><span>UX DESIGN</span></div>
                            </div>
                            <h1 className="workanimation">Design Systems and <br className="d-none d-lg-block" />Why They Matter</h1>
                            <p className="workanimation">7 Minute Read</p>
                        </div>
                        <div className='daterow workanimation'>
                            <p>FEBRUARY 7, 2023</p>
                            <p>BY FUTURE</p>
                        </div>
                        {/* <div className="row daterow workanimation">
                            <div className="col-6 blog-date"><h6>JANUARY 12, 2022</h6></div>
                            <div className="col-6 blog-author"><h6>BY FUTURE</h6></div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="container blog-content">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <h2 className="workanimation">Design systems are an essential part of the design process, particularly in the field of user experience (UX) design. At <span className='txt-bold'>Future,</span> it is a key component of creating a seamless and consistent user experience for our clients' customers.</h2>

                        <div className='blog-ww'>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <h2>But what exactly is a design system, and why do they matter?</h2>
                                </div>
                                <div className='col-md-7'>
                                    <p>A design system is a collection of design principles, guidelines, and reusable components that can be applied across a variety of digital products and platforms. It helps to ensure that all of a company's products and services have a cohesive look and feel, which can improve brand recognition and customer satisfaction.</p>
                                </div>
                            </div>
                        </div>

                        <div className='txt-bdr'>
                            <h2 className="workanimation">Here are a few reasons why design systems are important</h2>
                        </div>

                        <div className='txt-outline'>
                            <h4>01</h4>
                        </div>

                        <div className='txt-gradi'>
                            <h3>Aids in Consistency</h3>
                        </div>

                        <p className="workanimation">One of the main benefits of a design system is that it helps to create consistency across a company's products and services. This can improve the user experience and make it easier for users to recognize and trust the brand. For example, let's say a company has a mobile app and a website. </p>
                        <br />
                        <p className="workanimation">Without a design system, the designers working on these two products would have to come up with their own designs and styles for each element, such as buttons, navigation menus, and headings. This could result in a disjointed user experience, with the app and website feeling like two separate entities rather than part of the same brand.</p>
                        <br />

                        <div>
                            <img className='w-100' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img-2.png" />
                        </div>
                        <br />


                        <div className='txt-outline'>
                            <h4>02</h4>
                        </div>

                        <div className='txt-gradi'>
                            <h3>Better for Communication</h3>
                        </div>

                        <p className="workanimation">One of the main benefits of a design system is that it helps to create consistency across a company's products and services. This can improve the user experience and make it easier for users to recognize and trust the brand. For example, let's say a company has a mobile app and a website. </p>
                        <br />
                        <p className="workanimation">Without a design system, the designers working on these two products would have to come up with their own designs and styles for each element, such as buttons, navigation menus, and headings. This could result in a disjointed user experience, with the app and website feeling like two separate entities rather than part of the same brand.</p>
                        <br />

                        <div className='txt-outline'>
                            <h4>03</h4>
                        </div>

                        <div className='txt-gradi'>
                            <h3>Iterate Faster</h3>
                        </div>

                        <p className="workanimation">By using a design system, designers can iterate on product designs more quickly and efficiently. Instead of starting from scratch every time, they can use the design system to make updates and improvements to existing products and features. For example, if a design system specifies that all buttons should be a certain size, color, and shape, one change in the system can translate across all design assets instantly.</p>
                        <br />

                        <div>
                            <img className='w-100' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img-3.png" />
                        </div>
                        <br />

                        <div className='txt-outline'>
                            <h4>04</h4>
                        </div>

                        <div className='txt-gradi'>
                            <h3>SCALE FASTER</h3>
                        </div>

                        <p className="workanimation">By using a design system, designers can iterate on product designs more quickly and efficiently. Instead of starting from scratch every time, they can use the design system to make updates and improvements to existing products and features. For example, if a design system specifies that all buttons should be a certain size, color, and shape, one change in the system can translate across all design assets instantly.</p>
                        <br />

                        <div className='blog-sf'>
                            <div className='d-block'>
                                <img src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img-4.png" />
                            </div>
                            <div className='d-block'>
                                <img src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img-5.png" />
                            </div>
                            <div className='d-block'>
                                <img src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img-6.png" />
                            </div>
                        </div>
                        <br />
                        <br />

                        <div className='txt-outline'>
                            <h4>05</h4>
                        </div>

                        <div className='txt-gradi'>
                            <h3>Helps with Collaboration</h3>
                        </div>

                        <p className="workanimation">Design systems can facilitate collaboration within the design team and across departments. By providing clear guidelines and reusable components, a design system can make it easier for designers to work together and ensure that the final product meets the needs of the business. This is especially true when working with multiple teams on enterprise projects.</p>
                        <br />

                        <div>
                            <img className='w-100' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-ds-img-7.png" />
                        </div>
                        <br />

                        
                        
                    </div>
                    <div className="col-lg-2"></div>
                </div>
                {/* Work Section */}
                <div className="blog-temp-other">
                    <div className="fixed-work-cont">
                        <div className='bdr-top'></div>
                        <h4 className="workanimation">You might also like</h4>
                        <div className="row home-work-parent">
                            <div className="col-lg-4 col-md-6 blogs-post-cont">
                                <a className='text-decoration-none' href='/blog/the-importance-of-designing-for-usability'>
                                    <div className='home-work-child workanimation'>
                                        <div className='blog-crds-bdr'>
                                            <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-du-img.png" />
                                            <div className='blog-chips-cover'>
                                                <div className='blog-chip'><span>UX DESIGN</span></div>
                                            </div>
                                            <h3>The Importance of Designing <br /> for usability in UI and UX"</h3>
                                            <div className='blog-post-tl'>
                                                <p>7 Minute read</p>
                                                <p>JANUARY 24, 2023 | BY FUTURE </p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-6 blogs-post-cont">
                                <a className='text-decoration-none' href='/blog/the-role-of-resarch-and-product-design'>
                                    <div className='home-work-child workanimation'>
                                        <div className='blog-crds-bdr'>
                                            <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-rapd-img1.png" />
                                            <div className='blog-chips-cover'>
                                                <div className='blog-chip'><span>USER RESEARCH</span></div>
                                                <div className='blog-chip'><span>PRODUCT DESIGN</span></div>
                                            </div>
                                            <h3>Building Products <br /> People Love: The Role of <br /> Research in Product Design</h3>
                                            <div className='blog-post-tl'>
                                                <p>7 Minute read</p>
                                                <p>JANUARY 14, 2023 | BY FUTURE </p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-6 blogs-post-cont">
                                <a className='text-decoration-none' href='/blog/why-gamification-works-in-digital-product-design'>
                                    <div className='home-work-child workanimation'>
                                        <div className='blog-crds-bdr'>
                                            <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-smbi-img.png" />
                                            <div className='blog-chips-cover'>
                                                <div className='blog-chip'><span>UX DESIGN</span></div>
                                                <div className='blog-chip'><span>USER RESEARCH</span></div>
                                                <div className='blog-chip'><span>PRODUCT DESIGN</span></div>
                                            </div>
                                            <h3>Simple Models, Big Impact:<br /> Why Gamification Works in <br /> Digital Product Design</h3>
                                            <div className='blog-post-tl'>
                                                <p>6 Minute read</p>
                                                <p>FEBRUARY 16, 2023 | BY FUTURE </p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <a href="#"><h3 className="view-all">View all case studies</h3></a> */}
                </div>
                {/* Work Section End*/}
            </div>
            <Footer />
        </div>
     );
  }
}
