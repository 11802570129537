import React, { Component, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Api from "../../package.json";
import axios, * as others from 'axios';
import { useNavigate } from 'react-router-dom';
import ContactButton from "../components/maincontactbutton";

const qs = require("qs");
const Footer = () => {
    const headers = {
        type: "application/json",
    };
    const [captcha, setHeadercaptcha] = useState([]);
    const [SubmitButton, setSubmitButton] = useState("Send");
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const buttonRef = useRef();
    const navigate = useNavigate();
    const onSubmit = (data) => {
        buttonRef.current.disabled = true;
        setSubmitButton("SUBMITTING...");
        save_enquiry(data);
    };
    const save_enquiry = async (data) => {

        const response = await axios.post(
            Api.userdata.url + "/site/landing/save_enquiry_future",
            qs.stringify({
                name: data.name,
                email: data.email,
                phone: data.phone,
                message: data.services,
                budget: data.budget,
            })
        );
        const json = await response.data;
        if (json["status"] === "OK") {
            navigate('/thankyou');
            /*swal("Successful Submitted", json["message"], "success").then((value) => {
                window.location.reload(false);
            });*/
        } else {
            swal("Error !", json["message"], "error");
        }
        buttonRef.current.disabled = false;
        setSubmitButton("Submit");

    };
    useEffect(() => {
        // getLoadData();
    }, []);
    return (
        <>
            {/* <div className="d-lg-none d-block footer-nav-parent">
                        <div className="floating-nav">
                            <a href="#" className="footer-item active">Home</a>
                            <a href="#" className="footer-item">Cases</a>
                            <a href="#" className="footer-item">Process</a>
                            <a href="#" className="footer-item">Contact</a>
                        </div>
                    </div> */}
            <footer id="contact">
                <div className="footer-bg"></div>
                <div className="border-top border-bottom">
                    <div className="container footer-ff">
                        <div className="left">
                            
                            <h2>Build better digital products. <br className="d-none d-lg-block d-md-block" />Shape <br className="d-block d-lg-none d-md-none" /> your own Future.</h2>
                        </div>
                        <div className="right workanimation">
                            {/* <a href="/contact" className="extra-button">Get Started</a> */}
                            <ContactButton/>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="col-lg-7 d-none">
                        <h2 className="d-none">Join us on this <br />exciting journey.</h2>
                        <h2>Build better digital products. <br className="d-none d-lg-block d-md-block" />Shape <br className="d-block d-lg-none d-md-none" /> your own Future.</h2>
                    </div>
                    <div className="row footer-content">
                        <div className="col-lg-7 form-side d-none">
                            <form className="form-group" method="POST" onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor="name">What is your name</label>
                                {/* placeholder="Please enter your First and Last name" */}
                                <input type="text" name="name" 
                                    {...register("name", {
                                        required: {
                                            value: true,
                                            message: "Please enter your name",
                                        },
                                    })} />
                                {errors.name && (
                                    <span className="errors">{errors.name.message}</span>
                                )}

                                <label htmlFor="email">What is your email</label>
                                {/* placeholder="Please enter your work email" */}
                                <input type="email" name="email" 
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: "Please enter your email id",
                                        },
                                    })} />
                                {errors.email && (
                                    <span className="errors">{errors.email.message}</span>
                                )}

                                <label htmlFor="phone">What is your phone number</label>
                                {/* placeholder="Please enter your mobile number along with country code" */}
                                <input type="number" name="phone" 
                                    {...register("phone", {
                                        required: {
                                            value: true,
                                            message: "Please enter your mobile number",
                                        },
                                    })} />
                                {errors.phone && (
                                    <span className="errors">{errors.phone.message}</span>
                                )}

                                <label htmlFor="services">What services are you looking for?</label>
                                {/* placeholder="Please specify what you require" */}
                                <input type="text" name="services" 
                                    {...register("services", {
                                        required: {
                                            value: true,
                                            message: "Please tell us what you require",
                                        },
                                    })} />
                                {errors.services && (
                                    <span className="errors">{errors.services.message}</span>
                                )}

                                {/* <label htmlFor="landing-budget">Whats your Budget?</label>
                                <div className="radios-budget">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="budget" id="cbudget1" value="INR 1,50,000 - 3,00,000"
                                            {...register("budget", {
                                                required: {
                                                    value: true,
                                                    message: "Please select your budget",
                                                },
                                            })} />
                                        <label className="form-check-label" for="cbudget1">
                                            INR 1,50,000 - 3,00,000
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="budget" id="cbudget2" value="INR 3,50,000 - 5,00,000"
                                            {...register("budget", {
                                                required: {
                                                    value: true,
                                                    message: "Please select your budget",
                                                },
                                            })} />
                                        <label className="form-check-label" for="cbudget2">
                                            INR 3,50,000 - 5,00,000
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="budget" id="cbudget3" value="INR 5,00,000 - 7,00,000"
                                            {...register("budget", {
                                                required: {
                                                    value: true,
                                                    message: "Please select your budget",
                                                },
                                            })} />
                                        <label className="form-check-label" for="cbudget3">
                                            INR 5,00,000 - 7,00,000
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="budget" id="cbudget4" value="INR 7,00,000 & Above"
                                            {...register("budget", {
                                                required: {
                                                    value: true,
                                                    message: "Please select your budget",
                                                },
                                            })} />
                                        <label className="form-check-label" for="cbudget4">
                                            INR 7,00,000 & Above
                                        </label>
                                    </div>
                                    {errors.budget && (
                                        <span className="errors">{errors.budget.message}</span>
                                    )}
                                </div> */}
                                <label htmlFor="message">Your Message</label>
                                <textarea name="message" />
                                {/* placeholder="Can you help me with..." */}
                                <button id='main-submit' className="form-control" ref={buttonRef}> {SubmitButton}</button>
                            </form>
                        </div>
                        <div className="col-lg-1 d-none">
                        </div>
                        <div className="col-lg-4 contact">
                            <div className="c-block">
                                <p>FOR BUSINESS ENQUIRIES</p>
                                <a href="mailto:business@fingerprintsfuture.com" className="mail">business@fingerprintsfuture.com</a><br />
                                {/* <a href="tel:+91 91761 11675" className="number">+91 91761 11675</a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 contact">
                            <div className="c-block">
                                <p>FOR CAREERS</p>
                                <a href="mailto:careers@fingerprintsfuture.com" className="mail">careers@fingerprintsfuture.com</a><br />
                            </div>
                        </div>
                        <div className="col-lg-4 contact">
                            <div className="c-block d-none">
                                <p>GENERAL ENQUIRIES</p>
                                <a href="mailto:internal@fingerprintsfuture.com" className="mail">internal@fingerprintsfuture.com</a><br />
                                {/* <a href="tel:+91 91761 11675" className="number">+91 91761 11675</a> */}
                            </div>
                            <div className="c-block">
                                <p>LOCATED AT</p>
                                <a href="https://goo.gl/maps/rMpfydCApQeUt9zz9" target="_blank" rel="noreferrer" className="mail">15/4, 3rd Floor, Haddows Lane, <br className="d-none d-lg-block" /> Haddows Road, Nungambakkam, <br className="d-none d-lg-block" /> Chennai - 600006</a><br />
                            </div>
                        </div>
                        <div className="col-lg-4 contact">
                            <div className="c-block">
                                <span><a href="https://in.linkedin.com/company/fingerprintsfuture" target="_blank" rel="noreferrer" className="mail">LinkedIn</a></span>
                                <span><a href="https://www.instagram.com/fingerprintsfuture/" target="_blank" rel="noreferrer" className="mail">Instagram</a></span>
                            </div>
                        </div>
                    </div>
                    <div className="copyrights">
                        <p>2024 | Fingerprints Future</p>
                        <a href="/privacy" className="privacy-link"><p>Privacy Policy</p></a>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
