import React from "react";
import Footer from "../components/footer";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import BlogL1 from '../assets/blog-landing-img-1.png'
import Featured from '../assets/blog-landing-img-2.png'



class BlogTemp extends React.Component {
    
    blogtempGsap = () =>{

        gsap.registerPlugin(ScrollTrigger)
        gsap.set(".workanimation", { visibility: "hidden" });
        ScrollTrigger.batch(".workanimation", {
            onEnter: batch => {
                gsap.from(batch, {
                    duration: 1.5,
                    delay: 0,
                    yPercent: 50,
                    scale: 1,
                    autoAlpha: 0,
                    ease: "Power3.easeOut",
                    stagger: 0.4,
                    visibility: "visible",
                })
            },
            toggleActions: "play none none none",
            once: true,
            start: 'top 80%',
            end: 'top 40%',
            markers: false,
        });
        

        
    }
    

    componentDidMount(){
        this.blogtempGsap();
    }
    

    

    render() {


        return (
            <div className="blog-temp-parent">
                <style>
                    {
                        `.inner-page-btn {
                            display: none;
                        }`
                    }
                </style>
                <div className="home-cover-out"></div>
                <div className="container blog-temp-hero">
                    <div className="row main-hero-blog-row">
                        <div className="col-lg-5 bth-right-p workanimation">
                            <img alt="Fingerprints Future" src={Featured} className="img-fluid" />
                        </div>
                        <div className="col-lg-7 bth-left-p">
                            <div className="bth-left-p-cont">
                                <div className='blog-chips-cover workanimation'>
                                    <div className='blog-chip'><span>UX DESIGN</span></div>
                                </div>
                                <h1 className="workanimation">How Artificial Intelligence Will <br className="d-none d-lg-block" />Transform UI/UX Design</h1>
                                <p className="workanimation">7 Minute Read</p>
                            </div>
                            <div className="row daterow workanimation">
                                <div className="col-6 blog-date"><h6>JANUARY 4, 2022</h6></div>
                                <div className="col-6 blog-author"><h6>BY FUTURE</h6></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container blog-content">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                            <h2 className="workanimation">Design systems are an essential part of the design process, particularly in the field of user experience (UX) design. At Future, it is a key component of creating a seamless and consistent user experience for our clients' customers.</h2>

                            <h3 className="workanimation">But what exactly is a design system, and why do they matter?</h3>

                            <p className="workanimation">A design system is a collection of design principles, guidelines, and reusable components that can be applied across a variety of digital products and platforms. It helps to ensure that all of a company's products and services have a cohesive look and feel, which can improve brand recognition and customer satisfaction.</p>
                            <br />
                            <p className="workanimation">Once you’ve identified this group of people, it’s time to turn them into an individual or a character. For example, your audience of ‘time poor business executives’ can become one, named person with defining characteristics. By making the group a person, it’s so much easier to speak directly to them by using lots of ‘you/your’ rather than ‘I/we’. So rather than, 'this platform helps investors to achieve x', you’ll be prompted to say 'this platform helps you to improve your investments to achieve x'.</p><br /><br />
                            <h2 className="workanimation">Be authentic, and consistent</h2>
                            <p className="workanimation">Authenticity is key when it comes to developing your style. It might be useful to start by writing down a few words that capture the essence of your brand, your persona, your offering and your relationship with your audience. It’s tempting to assume the style of brands you admire, but question whether that would really work and whether it feels authentically ‘you’.</p><br />
                            <p className="workanimation">If you’re a relaxed and chatty brand, speak like that. If you pride yourself on your professional and corporate identity — lean into that. Your website is an extension of your brand and your copy should be reflective.</p><br />
                            <p className="workanimation">Once you feel like you’ve established an identity, try and put together a few tone of voice rules that you, and anyone working for you, can stick to. This will help ensure consistency, which in turn builds trust and recognition with your audience.</p><br /><br />
                            <h2 className="workanimation">Look at the whole picture</h2>
                            <p className="workanimation">As every copywriter has probably heard at least once, ‘everyone just looks at the pictures anyway.’ And while we obviously don’t subscribe to that statement at all, we do appreciate the importance of good imagery and design, and the importance of ensuring it complements your copy.</p><br />
                            <p className="workanimation">The two should work in tandem to strengthen your website and paint a clear picture of who you are and what you offer. If you’ve already established your tone of voice and brand persona it will be easier to select imagery and design elements that work in harmony. For example, do softer illustrative styles work better with your identity, or is your message better reinforced by a particular photographic style? Look at other sites for inspiration but remember to always do what feels natural and authentic to you.</p><br /><br />
                            <h2 className="workanimation">Look at the whole picture</h2>
                            <p className="workanimation">The two should work in tandem to strengthen your website and paint a clear picture of who you are and what you offer. If you’ve already established your tone of voice and brand persona it will be easier to select imagery and design elements that work in harmony. For example, do softer illustrative styles work better with your identity, or is your message better reinforced by a particular photographic style? Look at other sites for inspiration but remember to always do what feels natural and authentic to you.</p><br /><br />
                            <h2 className="workanimation">Simplicity is key</h2>
                            <p className="workanimation">However complex the product, service or idea you’re writing about is, conveying it as simply as possible is the best way to ensure you don’t lose your audience. There are many ways you can do this, but these are perhaps the most effective:</p><br /><br />
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                    {/* Work Section */}
        <div className="blog-temp-other">
            <div className="fixed-work-cont">
                <div className='bdr-top'></div>
                <h4 className="workanimation">You might also like</h4>
                <div className="row home-work-parent">
                <div className="col-lg-4 blogs-post-cont home-work-child workanimation">
                        <div className='blog-crds-bdr'>
                            <img alt="Fingerprints Future" className="img-fluid" src={BlogL1} />
                            <div className='blog-chips-cover'>
                                <div className='blog-chip'><span>UX DESIGN</span></div>
                            </div>
                            <h3>How Artificial Intelligence <br /> Will Transform UI/UX Design</h3>
                            <div className='blog-post-tl'>
                                <p>7 Minute read</p>
                                <p>JANUARY 4, 2022 | BY FUTURE </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 blogs-post-cont home-work-child workanimation">
                        <div className='blog-crds-bdr'>
                            <img alt="Fingerprints Future" className="img-fluid" src={BlogL1} />
                            <div className='blog-chips-cover'>
                                <div className='blog-chip'><span>UX DESIGN</span></div>
                            </div>
                            <h3>How Artificial Intelligence <br /> Will Transform UI/UX Design</h3>
                            <div className='blog-post-tl'>
                                <p>7 Minute read</p>
                                <p>JANUARY 4, 2022 | BY FUTURE </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 blogs-post-cont home-work-child workanimation">
                        <div className='blog-crds-bdr'>
                            <img alt="Fingerprints Future" className="img-fluid" src={BlogL1} />
                            <div className='blog-chips-cover'>
                                <div className='blog-chip'><span>UX DESIGN</span></div>
                            </div>
                            <h3>How Artificial Intelligence <br /> Will Transform UI/UX Design</h3>
                            <div className='blog-post-tl'>
                                <p>7 Minute read</p>
                                <p>JANUARY 4, 2022 | BY FUTURE </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <a href="#"><h3 className="view-all">View all case studies</h3></a> */}
        </div>
        {/* Work Section End*/}
                </div>
                <Footer />
            </div>
         );
    }
    
}
 
export default BlogTemp;