import React, { Component } from 'react';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

class UiuxDesigner extends Component {

    careeruiuxGsap = () =>{

        gsap.registerPlugin(ScrollTrigger)

        ScrollTrigger.saveStyles(".mobile, .desktop");

        ScrollTrigger.matchMedia({
            
            // desktop
            "(min-width: 800px)": function() {
                gsap.set(".workanimation", { visibility: "hidden" });
                ScrollTrigger.batch(".workanimation", {
                    onEnter: batch => {
                        gsap.from(batch, {
                            duration: 2,
                            delay: 0,
                            yPercent: 50,
                            scale: 1,
                            autoAlpha: 0,
                            ease: "Power3.easeOut",
                            stagger: 0.4,
                            visibility: "visible",
                        })
                    },
                    toggleActions: "play none none none",
                    once: true,
                    start: 'top 70%',
                    end: 'top 40%',
                    markers: false,
                });
            }, 
        
            // mobile
            "(max-width: 799px)": function() {
                gsap.set(".workanimation", { visibility: "hidden" });
                ScrollTrigger.batch(".workanimation", {
                    onEnter: batch => {
                        gsap.from(batch, {
                            duration: 2.2,
                            delay: 0,
                            yPercent: 50,
                            scale: 1,
                            autoAlpha: 0,
                            ease: "Power3.easeOut",
                            stagger: 0.4,
                            visibility: "visible",
                        })
                    },
                    toggleActions: "play none none none",
                    once: true,
                    start: 'top 70%',
                    end: 'top 40%',
                    markers: false,
                });
            }, 
        
            // all 
            "all": function() {
            }
        
        });
        
        // gsap.set(".workanimation", { visibility: "hidden" });
        // ScrollTrigger.batch(".workanimation", {
        //     onEnter: batch => {
        //         gsap.from(batch, {
        //             duration: 2.2,
        //             delay: 0.2,
        //             yPercent: 50,
        //             scale: 1,
        //             autoAlpha: 0,
        //             ease: "Power3.easeOut",
        //             stagger: 0.4,
        //             visibility: "visible",
        //         })
        //     },
        //     toggleActions: "play none none none",
        //     once: true,
        //     start: 'top 70%',
        //     end: 'top 40%',
        //     markers: false,
        // });
        gsap.set(".extra-button", {opacity: 0, yPercent: 40});
        gsap.to(".extra-button", {
            delay: 2,
            opacity: 1,
            yPercent: 0,
            duration: 0.7
        });

        gsap.set(".text-animate-tg", {opacity: 0, yPercent: 40});
        gsap.to(".text-animate-tg", {
            delay: 0.5,
            opacity: 1,
            yPercent: 0,
            duration: 1.5
        });

        gsap.set(".text-animate-tg1", {opacity: 0, yPercent: 40});
        gsap.to(".text-animate-tg1", {
            delay: 2,
            opacity: 1,
            yPercent: 0,
            duration: 2.5
        });

        gsap.set(".marquee-animate", {opacity: 0, yPercent: 0});
        gsap.to(".marquee-animate", {
            delay: 2,
            opacity: 1,
            yPercent: 0,
            duration: 2.5
        });
        gsap.set(".pcardscroll", { xPercent: 120 });
        gsap.to(".pcardscroll", {
            xPercent: 0,
            opacity: 1,
            duration: 2.5,
            delay: 2,
            ease: "Power3.easeOut",
            scrollTrigger: {
            trigger: ".pcardscroll",
            start: "top 100%",
            end: "bottom top"
            }
        });
        gsap.set(".pcardscroll1", { xPercent: 120 });
        gsap.to(".pcardscroll1", {
            xPercent: 0,
            opacity: 1,
            duration: 2.5,
            delay: 0,
            ease: "Power3.easeOut",
            scrollTrigger: {
            trigger: ".pcardscroll1",
            start: "top 50%",
            end: "bottom top"
            }
        });
        gsap.set(".sunnybee-cover", {opacity: 1});
        gsap.to(".sunnybee-cover", {
          opacity: 0,
          delay: 0,
          ease: "Power3.easeIn",
          duration: 0.3
        });
        gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
        });

        gsap.set(".process-approach .line span", {y: 180, rotateZ: 6});
        gsap.to(".process-approach .line span", {
            y: 0,
            rotateZ: 0,
            ease: "Power3.easeOut",
            delay: 0,
            stagger: {
            amount: 0.2
            },
            scrollTrigger: {
            trigger: ".process-approach .line span",
            start: "top 90%",
            }
        });

        gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })

        gsap.from("#myTab", 0.9, {
            y: 180,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 2.5,
            stagger: {
                amount: 0.2
            }
        });

        gsap.set(".our-process-txt1", { y: "27vw", rotateZ:"5deg" });
        gsap.to(".our-process-txt1", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.7,
        scrollTrigger: {
            trigger: ".our-process-txt1",
            start: "top 80%"
        }
        });
        gsap.set(".our-process-txt2", { y: "27vw", rotateZ:"5deg", });
        gsap.to(".our-process-txt2", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.71,
        scrollTrigger: {
            trigger: ".our-process-txt2",
            start: "top 100%"
        }
        });

        gsap.set(".our-process-txt3", { y: "27vw", rotateZ:"5deg", });
        gsap.to(".our-process-txt3", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.7,
        scrollTrigger: {
            trigger: ".our-process-txt3",
            start: "top 100%"
        }
        });        
        gsap.set(".our-process-txt4", { y: "27vw", rotateZ:"5deg" });
        gsap.to(".our-process-txt4", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.71,
        scrollTrigger: {
            trigger: ".our-process-txt4",
            start: "top 80%"
        }
        });
        gsap.set(".our-process-txt5", { y: "27vw", rotateZ:"5deg", });
        gsap.to(".our-process-txt5", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.72,
        scrollTrigger: {
            trigger: ".our-process-txt5",
            start: "top 100%"
        }
        });

        gsap.set(".our-process-txt6", { y: "27vw", rotateZ:"5deg", });
        gsap.to(".our-process-txt6", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.7,
        scrollTrigger: {
            trigger: ".our-process-txt6",
            start: "top 100%"
        }
        });        
        gsap.set(".our-process-txt7", { y: "27vw", rotateZ:"5deg" });
        gsap.to(".our-process-txt7", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.71,
        scrollTrigger: {
            trigger: ".our-process-txt7",
            start: "top 80%"
        }
        });

        gsap.set(".our-process-txt8", { y: "27vw", rotateZ:"5deg", });
        gsap.to(".our-process-txt8", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.7,
        scrollTrigger: {
            trigger: ".our-process-txt8",
            start: "top 100%"
        }
        });        
        gsap.set(".our-process-txt9", { y: "27vw", rotateZ:"5deg" });
        gsap.to(".our-process-txt9", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.71,
        scrollTrigger: {
            trigger: ".our-process-txt9",
            start: "top 80%"
        }
        });

        
    }
    

    componentDidMount(){
        this.careeruiuxGsap();

        // Input File Custom
        const resumeCV = document.getElementById('resume');

        const fileChosen = document.getElementById('file-chosen');

        resumeCV.addEventListener('change', function(){
        fileChosen.textContent = this.files[0].name
        })


        // Input File Custom
        const portfolioBtn = document.getElementById('portfolio');

        const fileChosen1 = document.getElementById('file-chosen1');

        portfolioBtn.addEventListener('change', function(){
        fileChosen1.textContent = this.files[0].name
        })
    }

    render() {
        return (
            <div className='overflow-hidden'>

                <div className="home-cover-out"></div>
                {/* <div className='sunnybee-cover'></div> */}
                <style>
                    {
                        `.inner-page-btn {
                            display: none;
                        }`
                    }
                </style>

                {/* Careers Hero */}
                <div className='work-hero careers-hero'>
                    <div className="d-none d-lg-block our-approach-gra"></div>
                    <div className="d-block d-lg-none our-approach-gra-resp"></div>
                    <div className='container work-hero-cont'>
                        <div className='blog-chips-cover'>
                            <div className='blog-chip text-animate-tg'><span>FULL TIME</span></div>
                        </div>
                        <div className='d-none d-lg-block d-md-block'>
                            <div>
                                <h2 className='line'><span>UI/UX Designer</span></h2>
                            </div>
                        </div>
                        <div className='d-block d-lg-none d-md-none work-hero-cont-h4'>
                            <h4 className='mobile-sunnybee-h4'>UI/UX Designer</h4>
                        </div>
                        <div className="">
                            <p className='text-animate-tg1'>0-2 Years Experience</p>
                        </div>
                    </div>
                </div>
                {/* Careers Hero End */}

                {/* Careers Marquee */}
                <div class="marquee marquee-animate">
                    <div class="track">
                        <div class="content">&nbsp;APPLY NOW <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> APPLY NOW <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> APPLY NOW <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> APPLY NOW <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> APPLY NOW <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> APPLY NOW <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> APPLY NOW <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> APPLY NOW <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> APPLY NOW <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /></div>
                    </div>
                </div>
                {/* Careers Marquee End */}

                <div className='careers-rq'>
                    <div className='container'>
                        <div className='row careers-rq-cont'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-2'>
                                <div>
                                    <h3 className='workanimation'><span>Responsibilities</span></h3>
                                </div>
                            </div>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-8'>
                                <ul>
                                    <li className='workanimation'>Collaborate with cross-functional teams to understand business requirements and design solutions</li>
                                    <li className='workanimation'>Conduct user research to identify and understand user needs, pain points and goals</li>
                                    <li className='workanimation'>Create wireframes, prototypes, and high-fidelity designs to effectively communicate design concepts</li>
                                    <li className='workanimation'>Develop and maintain design system, style guides and pattern libraries</li>
                                    <li className='workanimation'>Continuously validate design solutions through user testing and feedback</li>
                                    <li className='workanimation'>Stay up-to-date with current design trends and technologies to ensure designs are innovative and user-centered</li>
                                    <li className='workanimation'>Translate complex concepts into simple, intuitive, and aesthetically pleasing designs</li>
                                    <li className='workanimation'>Collaborate with developers to ensure design specifications are accurately implemented</li>
                                </ul>
                            </div>
                        </div>

                        <div className='row careers-rq-cont'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-2'>
                                <div>
                                    <h3 className='workanimation'><span>Qualitifications</span></h3>
                                </div>
                            </div>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-8'>
                                <ul>
                                    <li className='workanimation'>Bachelor's degree in Graphic Design, Human-Computer Interaction, or related field</li>
                                    <li className='workanimation'>3+ years of professional experience in UI/UX design</li>
                                    <li className='workanimation'>Strong portfolio showcasing experience in UX/UI design and design thinking</li>
                                    <li className='workanimation'>Proficiency in design and prototyping tools such as Sketch, Figma, InVision, Adobe Creative Suite, etc.</li>
                                    <li className='workanimation'>Understanding of HTML, CSS, and JavaScript</li>
                                    <li className='workanimation'>Excellent communication, interpersonal and problem-solving skills</li>
                                    <li className='workanimation'>Experience working in an Agile development environment</li>
                                    <li className='workanimation'>Ability to work in a fast-paced environment and handle multiple projects simultaneously</li>
                                    <li className='workanimation'>Passion for creating user-centered designs that solve real-world problems</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='careers-form'>
                    <div className="careers-bg"></div>
                    <div className='container'>
                        <div className='row careers-form-cont'>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-2'>
                                <div>
                                    <h3><span>Join us</span></h3>
                                </div>
                            </div>
                            <div className='col-lg-1'></div>
                            <div className='col-lg-6 form-side'>
                                <form className="form-group" method="POST" >
                                    <label htmlFor="name">What is your name</label>
                                    <input type="text" name="name" placeholder="Please enter your First and Last name" />

                                    <label htmlFor="email">What is your email</label>
                                    <input type="email" name="email" placeholder="Please enter your work email" />

                                    <label htmlFor="phone">What is your phone number</label>
                                    <input type="number" name="phone" placeholder="Please enter your mobile number along with country code" />

                                    <label htmlFor="file">Upload your Resume/CV</label>
                                    <input className='input-brows' type="file" id="resume" hidden/>
                                    <div className='d-flex'>
                                        <label for="resume">Browse</label>
                                        <span id="file-chosen"></span>
                                    </div>
                                    <label class="upr">*doc, docx, rtf, pdf under 12 MB</label>

                                    <label htmlFor="file">Portfolio</label>
                                    <input className='input-brows' type="file" id="portfolio" hidden/>
                                    <label for="portfolio">Browse</label>
                                    <span id="file-chosen1"></span>
                                    <label class="upr">*doc, docx, rtf, pdf under 12 MB</label>

                                    <label htmlFor="services">LinkedIn Profile</label>
                                    <input type="text" name="services" placeholder="Please share a valid LinkedIN URL" />

                                    <label htmlFor="services">Portfolio Website / Link</label>
                                    <input type="text" name="services" placeholder="Please share a valid Website URL" />

                                    {/* <label htmlFor="message">What services are you looking for?</label> */}
                                    {/* <textarea name="message" placeholder="Can you help me with..."/> */}
                                    <button id='main-submit' className="form-control" > Apply</button>
                                </form>
                            </div>
                            <div className='col-lg-2'></div>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default UiuxDesigner;
