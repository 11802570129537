import React, { Component } from 'react'
import Footer from '../components/footer'
import {Helmet} from "react-helmet";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class CareerLanding extends Component {

    careersGsap = () =>{

        gsap.registerPlugin(ScrollTrigger)

        ScrollTrigger.saveStyles(".mobile, .desktop");

        ScrollTrigger.matchMedia({
            
            // desktop
            "(min-width: 800px)": function() {
                gsap.set(".workanimation", { visibility: "hidden" });
                ScrollTrigger.batch(".workanimation", {
                    onEnter: batch => {
                        gsap.from(batch, {
                            duration: 2.2,
                            delay: 0.5,
                            yPercent: 50,
                            scale: 1,
                            autoAlpha: 0,
                            ease: "Power3.easeOut",
                            stagger: 0.4,
                            visibility: "visible",
                        })
                    },
                    toggleActions: "play none none none",
                    once: true,
                    start: 'top 70%',
                    end: 'top 40%',
                    markers: false,
                });
            }, 
        
            // mobile
            "(max-width: 799px)": function() {
                gsap.set(".workanimation", { visibility: "hidden" });
                ScrollTrigger.batch(".workanimation", {
                    onEnter: batch => {
                        gsap.from(batch, {
                            duration: 2.2,
                            delay: 0,
                            yPercent: 50,
                            scale: 1,
                            autoAlpha: 0,
                            ease: "Power3.easeOut",
                            stagger: 0.4,
                            visibility: "visible",
                        })
                    },
                    toggleActions: "play none none none",
                    once: true,
                    start: 'top 70%',
                    end: 'top 40%',
                    markers: false,
                });
            }, 
        
            // all 
            "all": function() {
            }
        
        });

        gsap.set(".extra-button", {opacity: 0, yPercent: 40});
        gsap.to(".extra-button", {
            delay: 2,
            opacity: 1,
            yPercent: 0,
            duration: 0.7
        });

        gsap.set(".marquee-animate", {opacity: 0, yPercent: 0});
        gsap.to(".marquee-animate", {
            delay: 2,
            opacity: 1,
            yPercent: 0,
            duration: 2.5,
        });
        gsap.set(".marquee-animate1", {opacity: 0, yPercent: 0});
        gsap.to(".marquee-animate1", {
            delay: 0.5,
            opacity: 1,
            yPercent: 0,
            duration: 2.5,
            ease: "Power3.easeOut",
            scrollTrigger: {
            trigger: ".marquee-animate1",
            start: "top 80%",
            end: "bottom top"
            }
        });
        gsap.set(".pcardscroll", { xPercent: 120 });
        gsap.to(".pcardscroll", {
            xPercent: 0,
            opacity: 1,
            duration: 2.5,
            delay: 2,
            ease: "Power3.easeOut",
            scrollTrigger: {
            trigger: ".pcardscroll",
            start: "top 100%",
            end: "bottom top"
            }
        });
        gsap.set(".pcardscroll1", { xPercent: 120 });
        gsap.to(".pcardscroll1", {
            xPercent: 0,
            opacity: 1,
            duration: 2.5,
            delay: 0,
            ease: "Power3.easeOut",
            scrollTrigger: {
            trigger: ".pcardscroll1",
            start: "top 50%",
            end: "bottom top"
            }
        });
        gsap.set(".sunnybee-cover", {opacity: 1});
        gsap.to(".sunnybee-cover", {
          opacity: 0,
          delay: 0,
          ease: "Power3.easeIn",
          duration: 0.3
        });
        gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
        });

        gsap.set(".process-approach .line span", {y: 180, rotateZ: 6});
        gsap.to(".process-approach .line span", {
            y: 0,
            rotateZ: 0,
            ease: "Power3.easeOut",
            delay: 0,
            stagger: {
            amount: 0.2
            },
            scrollTrigger: {
            trigger: ".process-approach .line span",
            start: "top 90%",
            }
        });

        gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })

        gsap.from("#myTab", 0.9, {
            y: 180,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 2.5,
            stagger: {
                amount: 0.2
            }
        });

        gsap.set(".our-process-txt1", { y: "27vw", rotateZ:"5deg" });
        gsap.to(".our-process-txt1", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.7,
        scrollTrigger: {
            trigger: ".our-process-txt1",
            start: "top 80%"
        }
        });
        gsap.set(".our-process-txt2", { y: "27vw", rotateZ:"5deg", });
        gsap.to(".our-process-txt2", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.71,
        scrollTrigger: {
            trigger: ".our-process-txt2",
            start: "top 100%"
        }
        });

        gsap.set(".our-process-txt3", { y: "27vw", rotateZ:"5deg", });
        gsap.to(".our-process-txt3", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.7,
        scrollTrigger: {
            trigger: ".our-process-txt3",
            start: "top 100%"
        }
        });        
        gsap.set(".our-process-txt4", { y: "27vw", rotateZ:"5deg" });
        gsap.to(".our-process-txt4", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.71,
        scrollTrigger: {
            trigger: ".our-process-txt4",
            start: "top 80%"
        }
        });
        gsap.set(".our-process-txt5", { y: "27vw", rotateZ:"5deg", });
        gsap.to(".our-process-txt5", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.72,
        scrollTrigger: {
            trigger: ".our-process-txt5",
            start: "top 100%"
        }
        });

        gsap.set(".our-process-txt6", { y: "27vw", rotateZ:"5deg", });
        gsap.to(".our-process-txt6", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.7,
        scrollTrigger: {
            trigger: ".our-process-txt6",
            start: "top 100%"
        }
        });        
        gsap.set(".our-process-txt7", { y: "27vw", rotateZ:"5deg" });
        gsap.to(".our-process-txt7", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.71,
        scrollTrigger: {
            trigger: ".our-process-txt7",
            start: "top 80%"
        }
        });

        gsap.set(".our-process-txt8", { y: "27vw", rotateZ:"5deg", });
        gsap.to(".our-process-txt8", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.7,
        scrollTrigger: {
            trigger: ".our-process-txt8",
            start: "top 100%"
        }
        });        
        gsap.set(".our-process-txt9", { y: "27vw", rotateZ:"5deg" });
        gsap.to(".our-process-txt9", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.71,
        scrollTrigger: {
            trigger: ".our-process-txt9",
            start: "top 80%"
        }
        });

        
    }
    

    componentDidMount(){
        this.careersGsap();
    }


  render() {
    return (
      <div className='overflow-hidden'>

        <div className="home-cover-out"></div>
        {/* <div className='sunnybee-cover'></div> */}
        <style>
            {
                `.inner-page-btn {
                    display: none;
                }`
            }
        </style>

        {/* Careers Hero */}
        <div className='work-hero careers-hero'>
            <div className="d-none d-lg-block our-approach-gra"></div>
            <div className="d-block d-lg-none our-approach-gra-resp"></div>
            <div className='container work-hero-cont'>
                <div className='d-none d-lg-block d-md-block'>
                    <div>
                        <h2 className='line'><span>The future of</span></h2>
                    </div>
                    <div>
                        <h2 className='line'><span>technology awaits.</span></h2>
                    </div>
                </div>
                <div className='d-block d-lg-none d-md-none work-hero-cont-h4'>
                    <h4 className='mobile-sunnybee-h4'>The future of <br /> technology awaits.</h4>
                </div>
                <br />
                <div className="extra-button-col">
                    <a href="/" target="_blank" className="extra-button">Explore all jobs</a>
                </div>
            </div>
        </div>
        {/* Careers Hero End */}

        
        {/* Careers Marquee */}
        <div class="marquee marquee-animate">
            <div class="track">
                <div class="content">&nbsp;JOIN THE FUTURE <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> JOIN THE FUTURE <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> JOIN THE FUTURE <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> JOIN THE FUTURE <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> JOIN THE FUTURE <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> JOIN THE FUTURE <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> JOIN THE FUTURE <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> JOIN THE FUTURE <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /></div>
            </div>
        </div>
        {/* Careers Marquee End */}

        <div className='our-principle'>
            <div className='container'>
                <div className='our-principle-cont'>
                    <div className='process-approach'>
                        <h3 className='line'><span>We’re guided by our principles</span></h3>
                    </div>

                    <div className='career-ll-cont'>
                        <div className="d-none d-lg-block our-principle-gra"></div>
                        <div className='career-ll-h2'>
                            <h2 className='our-process-txt1'><span className='gradi-txt'>Learn.</span></h2>
                        </div>
                        <div className='career-ll-h2'>
                            <h2 className='our-process-txt2'>Lead.</h2>
                        </div>
                        <p className='workanimation'>At Future, everyone is on a learning curve. Only by constantly updating <br className='d-none d-lg-block d-md-block' /> ourselves on the latest developments in tech can we deliver the very best <br className='d-none d-lg-block d-md-block' /> to our clients and partners. We empower each one of our team members to <br className='d-none d-lg-block d-md-block' /> be team leaders, helping them carve out a very special place in the Future.</p>
                    </div>

                    <br className='d-none d-lg-block d-md-block' />

                    <div className='career-ll-cont'>
                        <div className="d-none d-lg-block our-principle-gra"></div>
                        <div className='career-ll-h2'>
                            <h2 className='our-process-txt3'>Experience</h2>
                        </div>
                        <div className='career-ll-h2'>
                            <h2 className='our-process-txt4'>breeds</h2>
                        </div>
                        <div className='career-ll-h2'>
                            <h2 className='our-process-txt5'><span className='gradi-txt1'>expertise</span></h2>
                        </div>
                        <p className='workanimation'>We believe our in-house team possesses the very best skill sets in the <br className='d-none d-lg-block d-md-block' /> industry, with a wealth of knowledge and experience to back them up. By <br className='d-none d-lg-block d-md-block' /> giving each one of our members exposure to this domain expertise, we <br className='d-none d-lg-block d-md-block' /> ensure that they remain at the cutting edge of change.</p>
                    </div>

                    <br className='d-none d-lg-block d-md-block' />

                    <div className='career-ll-cont'>
                        <div className="d-none d-lg-block our-principle-gra"></div>
                        <div className='career-ll-h2'>
                            <h2 className='our-process-txt6'><span className='gradi-txt'>Growth</span></h2>
                        </div>
                        <div className='career-ll-h2'>
                            <h2 className='our-process-txt7'>is a skill</h2>
                        </div>
                        <p className='workanimation'>We don’t believe in staying still. By constantly upskilling and reskilling, we <br className='d-none d-lg-block d-md-block' /> remain restless in our pursuit of knowledge and help each one of our team <br className='d-none d-lg-block d-md-block' /> members pursue and achieve their long term goals. At Future, growth is <br className='d-none d-lg-block d-md-block' /> not just a given -  it’s a skill that is honed and perfected.</p>
                    </div>

                    <br className='d-none d-lg-block d-md-block' />

                    <div className='career-ll-cont'>
                        <div className="d-none d-lg-block our-principle-gra"></div>
                        <div className='career-ll-h2'>
                            <h2 className='our-process-txt8'>On a path</h2>
                        </div>
                        <div className='career-ll-h2'>
                            <h2 className='our-process-txt9'>to <span className='gradi-txt1'>progress</span></h2>
                        </div>
                        <p className='workanimation'>We are always on a progressive path - at Future, we have a need to <br className='d-none d-lg-block d-md-block' /> disrupt the status quo, to challenge the norms and to constantly push <br className='d-none d-lg-block d-md-block' /> the envelope. We seek people with the same passion , who can join us <br className='d-none d-lg-block d-md-block' /> on a quest to innovate and build the next. We are ready to take our <br className='d-none d-lg-block d-md-block' /> team as far as we can go, in the Future.</p>
                    </div>
                </div>
            </div>
        </div>


        {/* Careers Marquee */}
        <div class="marquee marquee-animate1">
            <div class="track">
                <div class="content">&nbsp;OPEN POSITIONS <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> OPEN POSITIONS <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> OPEN POSITIONS <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> OPEN POSITIONS <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> OPEN POSITIONS <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> OPEN POSITIONS <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> OPEN POSITIONS <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /> OPEN POSITIONS <img className='marquee-dot' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/marquee-dot.png" /></div>
            </div>
        </div>
        {/* Careers Marquee End */}

        <div className='careers-open-position'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-1 d-none d-lg-block d-md-none'></div>
                    <div className='col-lg-10 col-md-12'>
                        <a className='open-position-card workanimation careersuiux-link' href='/careers/ui-ux-designer'>
                            <div className='open-position-card-cont'>
                                <div>
                                    <h4>UI UX Designer</h4>
                                    <p>0-2 Years Experience</p>
                                </div>

                                <div>
                                    <a className='' href='/'>View Job</a>
                                </div>
                            </div>
                        </a>

                        <div className='open-position-card workanimation'>
                            <div className='open-position-card-cont'>
                                <div>
                                    <h4>Project Manager</h4>
                                    <p>0-2 Years Experience</p>
                                </div>

                                <div>
                                    <a className='' href='/'>View Job</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-1 d-none d-lg-block d-md-none'></div>
                </div>
            </div>
        </div>
      
      <Footer />
      </div>
    )
  }
}
