import React, { Component } from 'react'
import Footer from '../components/footer'
import {Helmet} from "react-helmet";
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrollTrigger from 'gsap/ScrollTrigger'


export default class drapesanddreams extends Component {
    projectGsap = () =>{
        gsap.registerPlugin(ScrollTrigger, SplitText)

        //Hero

        // gsap.from(".normal-header", 0.9, {
        //     opacity: 0,
        //     ease: "Power3.easeOut",
        //     delay: 3.5,
        //   })
          gsap.from("h2.line span", 0.9, {
            y: 180,
            rotateZ: 6,
            ease: "Power3.easeOut",
            delay: 0.7,
            stagger: {
              amount: 0.2
            }
          })
          gsap.from(".mobile-sunnybee-h4", 0.9, {
            y: 40,
            opacity: 0,
            ease: "Power3.easeOut",
            delay: 1.5,
            stagger: {
              amount: 0.2
            }
          })
          gsap.set(".sunnybee-hero", {height: "100vh"});
          gsap.to(".sunnybee-hero", {
            height: "80vh",
            delay: 1,
            ease: "Power3.easeOut",
            duration: 1
          });
          gsap.set(".sunnybee-hero-listitem li", { visibility: "hidden" });
          ScrollTrigger.batch(".sunnybee-hero-listitem li", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1,
                      opacity: 0,
                      delay: 1.2,
                      yPercent: 100,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.1,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              markers: false,
          });
          gsap.set(".sunnybee-cover", {opacity: 1});
          gsap.to(".sunnybee-cover", {
            opacity: 0,
            delay: 0,
            ease: "Power3.easeIn",
            duration: 0.3
          });
          gsap.set(".sunnybee-heading", {opacity: 0});
          gsap.to(".sunnybee-heading", {
            opacity: 1,
            delay: 0.1,
            ease: "Power3.easeIn",
            duration: 1
          });
          gsap.set(".sunnybee-hero-cont", {"margin-bottom": 0});
          gsap.to(".sunnybee-hero-cont", {
            "margin-bottom": 90,
            delay: 1,
            ease: "Power3.linear",
            duration: 1.5
          });
          gsap.set(".our-approach-gra", {xPercent: 100});
          gsap.to(".our-approach-gra", {
            xPercent: 0,
            delay: 2,
            ease: "Power3.easeOut",
            duration: 1.5
          });
          gsap.set(".project-text", { visibility: "hidden" });
          ScrollTrigger.batch(".project-text", {
              onEnter: batch => {
                  gsap.from(batch, {
                      duration: 1.5,
                      delay: 0,
                      yPercent: 50,
                      scale: 1,
                      autoAlpha: 0,
                      ease: "Power3.easeOut",
                      stagger: 0.4,
                      visibility: "visible",
                  })
              },
              toggleActions: "play none none none",
              once: true,
              start: 'top 80%',
              end: 'top 40%',
              markers: false,
          });

          gsap.set(".bdr-top", {width: "0%"});
          gsap.to(".bdr-top", {
            width: "100%",
            ease: "Power3.easeOut",
            duration: 1,
            scrollTrigger:{
                trigger: ".bdr-top",
                start: "top 80%"
            }
          });



    }
    

    componentDidMount(){
        this.projectGsap();
        
    }

  render() {
    return (
      <>
        <Helmet>
            <title>Website Design & Development for Drapes & Dreams - UI UX Web design | Fingerprints Future</title>
            {/* <meta name="description" content="Revamp Savittr's brand tone and aesthetic. Utilized design thinking to redesign the website with improved navigation and fluid animations" /> */}
        </Helmet>
      <div className="home-cover-out"></div>
        <div className='overflow-hidden'>
            {/* Fut Bank Hero */}
            <div className='sunnybee-hero'>
            <div className="our-approach-gra"></div>
                <div className='container sunnybee-hero-cont'>
                    <div className='row'>
                    <div className='col-lg-9'>
                            <div>
                                <h2 className='sunnybee-heading'>Drapesanddreams</h2>
                            </div>
                            <div className='d-none d-lg-block'>
                                <div>
                                    <h2 className='line'><span>Crafting an online presence </span></h2>
                                </div>
                                <div>
                                    <h2 className='line'><span>for a luxury home decor brand.</span></h2>
                                </div>
                            </div>
                            <div className='d-block d-lg-none'>
                                <h4 className='mobile-sunnybee-h4'>Crafting an online presence for a luxury home decor brand.</h4>
                            </div>
                        </div>
                        
                        <div className='col-lg-3 sunnybee-hero-listitem'>
                            <ul>
                                <li>Wireframing</li>
                                <li>USER EXPERIENCE DESIGN (UX)</li>
                                <li>USER INTERFACE DESIGN (UI)</li>
                                <li>RAPID PROTOTYPING</li>
                                <li>Micro-interaction Animation</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fut Bank Hero End */}

            <div className='container sunnybee-video1 hide-nav'>
                <img alt="Fingerprints Future" className='img-fluid' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dandd-img-1.png" />
                {/* <video src={Video1} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
            </div>

            {/* Fut Bank About */}
            <div className='sunnybee-about'>
                <div className="d-none d-lg-block sunnybee-gra1"></div>
                <div className="d-block d-lg-none sunnybee-gra1-reps"></div>
                <div className='container sunnybee-about-cont'>
                    <div className='row'>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-5'>
                            <div>
                                <h4 className='project-text'>What went into the</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>creation of the website?</h4>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div>
                                <p className='project-text'>When we undertook this project, we channeled the founder’s vision of a premium, luxurious home decor brand, curated for a discerning audience. Therefore, we gave the website a refined aesthetic while also making it easily navigable, adding fluid animations to improve the user experience. The new, modern, visually-appealing site was extremely easy to use resulting in an increased number of leads with a clear call to action for users.</p>
                            </div>
                        </div>
                        <div className='col-lg-2'></div>
                    </div>
                </div>
            </div>
            {/* Fut Bank About End */}

            <div className='container sunnybee-about-img'>
                <div className='row'>
                    <div className='col-lg-12 dandd-img2'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dandd-img-2.png" />
                    </div>
                    <div className='col-lg-6 dandd-img3'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dandd-img-3.png" />
                        {/* <video src={Video1} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
                    </div>
                    <div className='col-lg-6'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dandd-img-4.png" />
                    </div>
                </div>
            </div>

            {/* Fut Bank About */}
            <div className='sunnybee-about'>
                <div className='container dandd-about-cont'>
                    <div className='row'>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-8 d-none d-lg-block'>
                            <div>
                                <h4 className='project-text'>A visually stunning experience that brings a sense of</h4>
                            </div>
                            <div>
                                <h4 className='project-text'>luxury and premium quality to the user. This is achieved </h4>
                            </div>
                            <div>
                                <h4 className='project-text'>through the use of large, bold text, immersive imagery, </h4>
                            </div>
                            <div>
                                <h4 className='project-text'>and smooth, seamless interactions.</h4>
                            </div>
                        </div>
                        <div className='d-block d-lg-none futbank-cont-resp'>
                            <h4 className='project-text'>A visually stunning experience that brings a sense of luxury and premium quality to the user. This is achieved through the use of large, bold text, immersive imagery, and smooth, seamless interactions.</h4>
                        </div>
                        <div className='col-lg-3'></div>
                    </div>
                </div>
            </div>
            {/* Fut Bank About End */}

            <div className='container dandd-img5-6-7'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dandd-img-5.png" />
                    </div>
                    <div className='col-lg-6'>
                        {/* <img alt="Fingerprints Future" className='img-fluid' src={Img6} /> */}
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/dandd-video-2.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className='col-lg-6'>
                        {/* <img alt="Fingerprints Future" className='img-fluid' src={Img7} /> */}
                        <video className='project-text' src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/dandd-video-3.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                </div>
            </div>

            <div className='container savittr-creative-contress'>
                <div className='row'>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-2 savittr-img6'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dandd-img-8.png" />
                    </div>
                    <div className='col-lg-2 savittr-img7'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dandd-img-9.png" />
                    </div>
                    <div className='col-lg-2'></div>
                    <div className='col-lg-4 align-self-center'>
                        <h4 className='project-text'>Bringing the brand to life</h4>
                        <p className='project-text'>With the use of brand colours in tones that reflect luxury and quality, a polished, refined aesthetic that conveys the quiet, understated nature of the brand and a tone of voice that adheres to the way the brand should speak to its customers, we were able to create a sharp and consistent brand personality across platform.</p>
                    </div>
                    <div className='col-lg-1'></div>
                </div>
            </div>

            <div className='container savittr-img8'>
                <div className='row'>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-10'>
                        <img alt="Fingerprints Future" className='img-fluid project-text' src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dandd-img-10.svg" />
                    </div>
                    <div className='col-lg-1'></div>
                </div>
            </div>

        </div>


        {/* Work Section */}
        <div className="sunnybee-work">
            <div className="container fixed-work-cont">
                <div className='bdr-top'></div>
                <h4 className='project-text'>You might also like</h4>
                <div className="row home-work-parent">
                <div className="col-lg-6  home-work-child project-text">
                                <a className="sunnybee-link" href="/work/sunnybee"></a>
                                <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybeex2.png" />
                                <h3>SunnyBee Market</h3>
                                <p>Mobile App</p>
                            </div>
                            <div className="col-lg-6 home-work-child project-text">
                                <a className="savittr-link" href="/work/savittr"></a>
                                <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittrx2.png" />
                                <h3>Savittr</h3>
                                <p>Wordpress Design & Development</p>
                            </div>
                </div>
            </div>
            {/* <a href="#"><h3 className="view-all">View all case studies</h3></a> */}
        </div>
        {/* Work Section End*/}

      <Footer />
      </>
    )
  }
}
