import React, { Component } from "react";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class work extends Component {
  workGsap = () => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.set(".svg1", { visibility: "hidden" });
    ScrollTrigger.batch(".svg1", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1,
          opacity: 1,
          delay: 0,
          yPercent: 100,
          rotateZ: 5,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.08,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      start: "top 80%",
      end: "top 40%",
      markers: false,
    });
    gsap.set(".sunnybee-cover", { opacity: 1 });
    gsap.to(".sunnybee-cover", {
      opacity: 0,
      delay: 0,
      ease: "Power3.easeIn",
      duration: 0.3,
    });

    gsap.set(".workanimation", { visibility: "hidden" });
    ScrollTrigger.batch(".workanimation", {
      onEnter: (batch) => {
        gsap.from(batch, {
          duration: 1.5,
          delay: 0,
          yPercent: 50,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.4,
          visibility: "visible",
        });
      },
      toggleActions: "play none none none",
      once: true,
      start: "top 80%",
      end: "top 40%",
      markers: false,
    });
    gsap.set(".sunnybee-cover", { opacity: 1 });
    gsap.to(".sunnybee-cover", {
      opacity: 0,
      delay: 0,
      ease: "Power3.easeIn",
      duration: 0.3,
    });
    gsap.from("h2.line span", 0.9, {
      y: 180,
      rotateZ: 6,
      ease: "Power3.easeOut",
      delay: 1.5,
      stagger: {
        amount: 0.2,
      },
    });
    gsap.from(".line", 0.9, {
      duration: 1.5,
      delay: 2,
      yPercent: 100,
      scale: 1,
      autoAlpha: 0,
      ease: "Power3.easeOut",
      stagger: 0.4,
      visibility: "visible",
    });
    gsap.from("#myTab", 0.9, {
      y: 180,
      opacity: 0,
      ease: "Power3.easeOut",
      delay: 2.5,
      stagger: {
        amount: 0.2,
      },
    });
  };

  componentDidMount() {
    this.workGsap();
  }

  render() {
    return (
      <div className="work-parent overflow-hidden">
        <Helmet>
          <title>
            Explore design case studies and get inspired by our UI/UX portfolio
            at Fingerprints Future.
          </title>
          <meta
            name="description"
            content="Discover the best design case study examples, including UI/UX design case study inspiration, top product design case studies, website design case studies, and step-by-step UX case studies, all in one place."
          />
        </Helmet>
        <div className="home-cover-out"></div>
        <div className="sunnybee-cover"></div>

        <style>
          {`.inner-page-btn {
                    display: none;
                }
                .home-work-parent h3 {
                    color: #000;
                }
                .nav-tabs .nav-link {
                    color: #000;
                }
                .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
                    color: #000;
                    border-top: 1px solid #000 !important;
                }`}
        </style>

        {/* Work Hero */}
        <div className="work-hero">
          {/* <div className="d-none d-lg-block our-approach-gra"></div> */}
          {/* <div className="d-block d-lg-none our-approach-gra-resp"></div> */}
          <div className="container work-hero-cont">
            <div className="d-none d-lg-block d-md-block">
              <div>
                <h2 className="line">
                  <span>Playground of the Future</span>
                </h2>
                <p className="work-hero-cont-p line">
                  We are an UI/UX organization that{" "}
                  <br className="d-block d-lg-none d-md-none" /> brings ideas
                  <br className="d-none d-lg-block d-md-block" /> to life
                  through <br className="d-block d-lg-none d-md-none" /> digital
                  experiences.
                </p>
              </div>
            </div>
            <div className="d-block d-lg-none d-md-none work-hero-cont-h4">
              <h4 className="mobile-sunnybee-h4">
                Playground of <br /> the Future
              </h4>
              <p className="work-hero-cont-p ">
                We are an UI/UX organization that{" "}
                <br className="d-block d-lg-none d-md-none" /> brings ideas
                <br className="d-none d-lg-block d-md-block" /> to life through{" "}
                <br className="d-block d-lg-none d-md-none" /> digital
                experiences.
              </p>
            </div>
          </div>
        </div>
        {/* Work Hero End */}

        {/* Work Projects */}
        <div className="work-project">
          <div className="container">
            <ul className="nav nav-tabs " id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true">
                  All Work
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false">
                  Websites
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false">
                  Products
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab">
                <div className="row home-work-parent">
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="mahasaghar-link" href="/work/mahasaghar"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/mahasaghar-cover-sm.png"
                    />
                    <h3>Mahāsa Ghar</h3>
                    <p>E-Commerce Website Design & Development</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="ocacademy-link" href="/work/oc-academy"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/ocacademy-img-cover-sm.png"
                    />
                    <h3>OC Academy</h3>
                    <p>Website Design</p>
                  </div>
                  <div className="col-lg-6 col-md-6  home-work-child workanimation">
                    <a className="sunnybee-link" href="/work/sunnybee"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybeex2.png"
                    />
                    <h3>SunnyBee Market</h3>
                    <p>Mobile App</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="savittr-link" href="/work/savittr"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittrx2.png"
                    />
                    <h3>Savittr</h3>
                    <p>Wordpress Design & Development</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="e-wallet-link" href="/work/e-wallet"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet2x2.png"
                    />
                    <h3>E-Wallet</h3>
                    <p>Mobile App</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a
                      className="dealsplus-product-link"
                      href="/work/dealsplus-product"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-thumbx2.png"
                    />
                    <h3>Deals Plus</h3>
                    <p>Product Design</p>
                  </div>
                  <div className="col-lg-6 col-md-6  home-work-child workanimation">
                    <a
                      className="dealsplus-website-link"
                      href="/work/dealsplus-website"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-cover-img-nw.png"
                    />
                    <h3>Deals Plus</h3>
                    <p>Website Design & Development</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="fut-bank-link" href="/work/fut-bank"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/futx2.png"
                    />
                    <h3>Fut Bank</h3>
                    <p>Product Design</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="authorcafe-link" href="/work/authorcafe"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-thumbx2.png"
                    />
                    <h3>Authorcafe</h3>
                    <p>Website Design & Development</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a
                      className="crypto-wallet-link"
                      href="/work/crypto-wallet"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/crypto-cover-2x.png"
                    />
                    <h3>Crypto Wallet</h3>
                    <p>Product Design</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="flyet-link" href="/work/Flyet"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/flyet-cover-2x.png"
                    />
                    <h3>Flyet</h3>
                    <p>Web App</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="tnq-link" href="/work/tnq"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tnq-cover-1-2x.png"
                    />
                    <h3>TNQ</h3>
                    <p>Website Design & Development</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="eleveight-link" href="/work/eleveight"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/eleveight-img-cover.png"
                    />
                    <h3>Eleveight</h3>
                    <p>Website Design & Development</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="xtm-link" href="/work/xtm"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-img-cover.png"
                    />
                    <h3>XTM</h3>
                    <p>Website Design & Development</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="healthcare-link" href="/work/healthcare"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/health-care-cover.png"
                    />
                    <h3>Healthcare</h3>
                    <p>Product Design</p>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab">
                <div className="row home-work-parent">
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="mahasaghar-link" href="/work/mahasaghar"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/mahasaghar-cover-sm.png"
                    />
                    <h3>Mahāsa Ghar</h3>
                    <p>E-Commerce Website Design & Development</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="ocacademy-link" href="/work/oc-academy"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/ocacademy-img-cover-sm.png"
                    />
                    <h3>OC Academy</h3>
                    <p>Website Design</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="savittr-link" href="/work/savittr"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittrx2.png"
                    />
                    <h3>Savittr</h3>
                    <p>Wordpress Design & Development</p>
                  </div>

                  <div className="col-lg-6 col-md-6  home-work-child workanimation">
                    <a
                      className="dealsplus-website-link"
                      href="/work/dealsplus-website"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-website-cover-img-nw.png"
                    />
                    <h3>Deals Plus</h3>
                    <p>Website Design & Development</p>
                  </div>

                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="authorcafe-link" href="/work/authorcafe"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-thumbx2.png"
                    />
                    <h3>Authorcafe</h3>
                    <p>Website Design & Development</p>
                  </div>

                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="tnq-link" href="/work/tnq"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/tnq-cover-1-2x.png"
                    />
                    <h3>TNQ</h3>
                    <p>Website Design & Development</p>
                  </div>

                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="eleveight-link" href="/work/eleveight"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/eleveight-img-cover.png"
                    />
                    <h3>Eleveight</h3>
                    <p>Website Design & Development</p>
                  </div>
                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="xtm-link" href="/work/xtm"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/xtm-img-cover.png"
                    />
                    <h3>XTM</h3>
                    <p>Website Design & Development</p>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab">
                <div className="row home-work-parent">
                  <div className="col-lg-6 col-md-6  home-work-child workanimation">
                    <a className="sunnybee-link" href="/work/sunnybee"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybeex2.png"
                    />
                    <h3>SunnyBee Market</h3>
                    <p>Mobile App</p>
                  </div>

                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a
                      className="dealsplus-product-link"
                      href="/work/dealsplus-product"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-thumbx2.png"
                    />
                    <h3>Deals Plus</h3>
                    <p>Product Design</p>
                  </div>

                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="fut-bank-link" href="/work/fut-bank"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/futx2.png"
                    />
                    <h3>Fut Bank</h3>
                    <p>Product Design</p>
                  </div>

                  <div className="col-lg-6  home-work-child workanimation">
                    <a className="e-wallet-link" href="/work/e-wallet"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet2x2.png"
                    />
                    <h3>E-Wallet</h3>
                    <p>Mobile App</p>
                  </div>

                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a
                      className="crypto-wallet-link"
                      href="/work/crypto-wallet"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/crypto-cover-2x.png"
                    />
                    <h3>Crypto Wallet</h3>
                    <p>Product Design</p>
                  </div>

                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="flyet-link" href="/work/flyet"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/flyet-cover-2x.png"
                    />
                    <h3>Flyet</h3>
                    <p>Product Design</p>
                  </div>

                  <div className="col-lg-6 col-md-6 home-work-child workanimation">
                    <a className="healthcare-link" href="/work/healthcare"></a>
                    <img
                      alt="Fingerprints Future"
                      className="img-fluid"
                      src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/health-care-cover.png"
                    />
                    <h3>Healthcare</h3>
                    <p>Product Design</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Work Projects End */}

        <Footer />
      </div>
    );
  }
}
