import React, { Component, useEffect, useState, useRef } from "react";
import Lottie from "lottie-react";
import Slider from "react-slick";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";
import {Helmet} from "react-helmet";

//Assets

import Infinity from "../assets/new-inifnite-450.json"
import ResearchJson from '../assets/magnifier icon-02.json'
import DesignJson from '../assets/square icons.json'
import BuildJson from '../assets/open bracket-02.json'
import InfinitySection from "../components/infinitysection";


import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Api from "../../package.json";
import axios, * as others from 'axios';
import { useNavigate } from 'react-router-dom';
const qs = require("qs");
const LFooter = () => {
    const headers = {
        type: "application/json",
    };
    const [captcha, setHeadercaptcha] = useState([]);
    const [SubmitButton, setSubmitButton] = useState("Send");
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const buttonRef = useRef();
    const navigate = useNavigate();
    const onSubmit = (data) => {
        buttonRef.current.disabled = true;
        setSubmitButton("SUBMITTING...");
        save_enquiry(data);
    };
    const save_enquiry = async (data) => {
      
            const response = await axios.post(
                Api.userdata.url + "/site/landing/save_enquiry_future",
                qs.stringify({
                    name: data.name,
                    email: data.email,
                    phone: data.phone,
                    message: data.services,
                })
            );
            const json = await response.data;
            if (json["status"] === "OK") {
                navigate('/thankyou');
                /*swal("Successful Submitted", json["message"], "success").then((value) => {
                    window.location.reload(false);
                });*/
            } else {
                swal("Error !", json["message"], "error");
            }
            buttonRef.current.disabled = false;
            setSubmitButton("Submit");
                
            };
            useEffect(() => {
                // getLoadData();
            }, []);
    return ( 
        <>
            {/* <div className="d-lg-none d-block footer-nav-parent">
                <div className="floating-nav">
                    <a href="#" className="footer-item active">Home</a>
                    <a href="#" className="footer-item">Cases</a>
                    <a href="#" className="footer-item">Process</a>
                    <a href="#" className="footer-item">Contact</a>
                </div>
            </div> */}
            <footer id="contact">
                <div className="footer-bg"></div>
                <div className="container">
                    <div className="col-lg-7">
                        <h2>Join us on this <br />exciting journey.</h2>
                    </div>
                    <div className="row footer-content">
                        <div className="col-lg-7 form-side">
                        <form className="form-group" method="POST" onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor="name">What is your name</label>
                                <input type="text" name="name" placeholder="Please enter your First and Last name" 
                                {...register("name", {
                                        required: {
                                            value: true,
                                            message: "Please enter your name",
                                        },
                                    })} />
                                                                    {errors.name && (
                                            <span className="errors">{errors.name.message}</span>
                                        )}
                                    
                                <label htmlFor="email">What is your email</label>
                                <input type="email" name="email" placeholder="Please enter your work email" 
                               {...register("email", {
                                required: {
                                    value: true,
                                    message: "Please enter your email id",
                                },
                            })}/>
                            {errors.email && (
                                    <span className="errors">{errors.email.message}</span>
                                )}

                                <label htmlFor="phone">What is your phone number</label>
                                <input type="number" name="phone" placeholder="Please enter your mobile number along with country code" 
                                {...register("phone", {
                                    required: {
                                        value: true,
                                        message: "Please enter your mobile number",
                                    },
                                })}/>
                                                                {errors.phone && (
                                    <span className="errors">{errors.phone.message}</span>
                                )}

                                <label htmlFor="services">What services are you looking for?</label>
                                <input type="text" name="services" placeholder="Please specify what you require" 
                                {...register("services", {
                                    required: {
                                        value: true,
                                        message: "Please tell us what you require",
                                    },
                                })}/>
                                                                {errors.services && (
                                    <span className="errors">{errors.services.message}</span>
                                )}

                                <label htmlFor="landing-budget">Whats your Budget?</label>
                                <div className="radios-budget">
                                    <div className="form-check">
                                    <input className="form-check-input" type="radio" name="budget" id="budget1" />
                                    <label className="form-check-label" for="budget1">
                                        INR 1,50,000 - 3,00,000
                                    </label>
                                    </div>
                                    <div className="form-check">
                                    <input className="form-check-input" type="radio" name="budget" id="budget2" />
                                    <label className="form-check-label" for="budget2">
                                        INR 3,50,000 - 5,00,000
                                    </label>
                                    </div>
                                    <div className="form-check">
                                    <input className="form-check-input" type="radio" name="budget" id="budget3" />
                                    <label className="form-check-label" for="budget3">
                                        INR 5,00,000 - 7,00,000
                                    </label>
                                    </div>
                                    <div className="form-check">
                                    <input className="form-check-input" type="radio" name="budget" id="budget4" />
                                    <label className="form-check-label" for="budget4">
                                        INR 7,00,000 & Above
                                    </label>
                                    </div>
                                </div>
                                
                                {/* <label htmlFor="message">What services are you looking for?</label> */}
                                {/* <textarea name="message" placeholder="Can you help me with..."/> */}
                                <button id='main-submit' className="form-control" ref={buttonRef}> {SubmitButton}</button>
                            </form>
                        </div>
                        <div className="col-lg-1">
                        </div>
                        <div className="col-lg-4 contact">
                            <div className="c-block">
                                <h5>FOR BUSINESS ENQUIRIES</h5>
                                <a href="mailto:business@fingerprintsfuture.com" className="mail">business@fingerprintsfuture.com</a><br />
                                <a href="tel:+91 91761 11675" className="number">+91 91761 11675</a>
                            </div>
                            <div className="c-block">
                                <h5>FOR CAREERS</h5>
                                <a href="mailto:careers@fingerprintsfuture.com" className="mail">careers@fingerprintsfuture.com</a><br />
                            </div>
                            <div className="c-block d-none">
                                <h5>GENERAL ENQUIRIES</h5>
                                <a href="mailto:internal@fingerprintsfuture.com" className="mail">internal@fingerprintsfuture.com</a><br />
                                <a href="tel:+91 91761 11675" className="number">+91 91761 11675</a>
                            </div>
                            <div className="c-block">
                                <h5>LOCATED AT</h5>
                                <a href="https://goo.gl/maps/rMpfydCApQeUt9zz9" target="_blank" className="mail">No 3, 17th Avenue, Harrington <br className="d-none d-lg-block"/>Road, Chennai - 600031</a><br />
                            </div>
                            <div className="c-block d-none">
                                <span><a href="/" className="mail">LinkedIn</a></span>
                                <span><a href="/" className="mail">Instagram</a></span>
                            </div>
                        </div>
                    </div>
                    <div className="copyrights">
                        <h6>2024 | Fingerprints Future</h6>
                        <a href="/privacy" className="privacy-link"><h6>Privacy Policy</h6></a>
                    </div>
                </div>
            </footer>
        </>
     );
}
 





class Landing extends React.Component {
    

    

    homeGsap = () =>{


        gsap.registerPlugin(ScrollTrigger, SplitText)
           
        
        
        // Home Hero Timeline

            gsap.from(".normal-header", 0.9, {
              opacity: 0,
              ease: "Power3.easeOut",
              delay: 2.5,
            })
        gsap.from("h1.line span", 0.9, {
          y: 180,
          rotateZ: 6,
          ease: "Power3.easeOut",
          delay: 2.5,
          stagger: {
            amount: 0.15
          }
        })
        gsap.set(".home-hero-gra-mob", {opacity: 0});
        gsap.to(".home-hero-gra-mob", {
          yPercent: 0,
          opacity: 1,
          ease: "Power3.easeOut",
          delay: 2,
          duration: 1.5
        });
        gsap.set(".home-hero-gra", {yPercent: 100});
        gsap.to(".home-hero-gra", {
          yPercent: 0,
          ease: "Power3.easeOut",
          delay: 2,
          duration: 1.5
        });
        gsap.set(".hh-mobile-h1 h1", {opacity: 0, yPercent: 20});
        gsap.to(".hh-mobile-h1 h1", {
            delay: 3,
            opacity: 1,
            yPercent: 0,
            duration: 0.7
        });
        gsap.set("#hero .extra-button", {opacity: 0, yPercent: 40});
        gsap.to("#hero .extra-button", {
            delay: 3.4,
            opacity: 1,
            yPercent: 0,
            duration: 0.7
        });
        gsap.set(".footer-nav-parent", {opacity: 0});
        gsap.to(".footer-nav-parent", {
            delay: 3,
            opacity: 1,
            yPercent: 0,
            duration: 0.7
        });
        gsap.set(".ha-mobile-h2 h2", {opacity: 0, yPercent: 20});
        gsap.to(".ha-mobile-h2 h2", {
            opacity: 1,
            yPercent: 0,
            scrollTrigger: {
                trigger: ".ha-mobile-h2 h2",
                start: "top 70%",
            }
        });
        gsap.set(".ha-mobile-a a", {opacity: 0, yPercent: 20});
        gsap.to(".ha-mobile-a a", {
            opacity: 1,
            yPercent: 0,
            scrollTrigger: {
                trigger: ".ha-mobile-a a",
                start: "top 70%",
            }
        });
        gsap.set(".home-about .line span", {y: 180, rotateZ: 6});
        gsap.to(".home-about .line span", {
          y: 0,
          rotateZ: 0,
          ease: "Power3.easeOut",
          delay: 0,
          stagger: {
            amount: 0.15
          },
          scrollTrigger: {
            trigger: ".home-about .line span",
            start: "top 90%",
          }
        });

        gsap.set(".home-clients .line span", {y: 180, rotateZ: 6});
        gsap.to(".home-clients .line span", {
          y: 0,
          rotateZ: 0,
          ease: "Power3.easeOut",
          delay: 0,
          stagger: {
            amount: 0.15
          },
          scrollTrigger: {
            trigger: ".home-clients .line span",
            start: "top 90%",
          }
        });

        gsap.set(".home-what .line span", {y: 180, rotateZ: 6});
        gsap.to(".home-what .line span", {
          y: 0,
          rotateZ: 0,
          ease: "Power3.easeOut",
          delay: 0,
          duration: 1,
          scrollTrigger: {
            trigger: ".home-what .line span",
            start: "top 90%",
          }
        });
        gsap.set(".hw-mobile-h2 h2", {opacity: 0, yPercent: 20});
        gsap.to(".hw-mobile-h2 h2", {
            opacity: 1,
            yPercent: 0,
            scrollTrigger: {
                trigger: ".hw-mobile-h2 h2",
                start: "top 70%",
            }
        });
        gsap.set(".home-what a", {opacity: 0});
        gsap.to(".home-what a", {
          opacity: 1,
          ease: "Power3.easeOut",
          delay: 0.7,
          scrollTrigger: {
            trigger: ".home-what .line span",
            start: "top 90%",
          }
        });
        gsap.set(".home-about .link", {opacity: 0});
        gsap.to(".home-about .link", {
          opacity: 1,
          ease: "Power3.easeOut",
          delay: 1,
          scrollTrigger: {
            trigger: ".home-about .line span",
            start: "top 90%",
          }
        });
        gsap.set(".home-about-inner2-h2", { opacity: 0 });
        gsap.to(".home-about-inner2-h2", {
        opacity: 1, 
        ease: "Power3.easeOut",
        duration: 1,
        scrollTrigger: {
            trigger: ".home-about-inner2-h2",
            start: "top 60%",
        }
        });
        gsap.set(".home-about-inner2-line", { width: "0%" });
        gsap.to(".home-about-inner2-line", {
        width: '73.3%',
        ease: "Power3.easeOut",
        delay: .5,
        duration: 1,
        scrollTrigger: {
            trigger: ".home-about-inner2-line",
            start: "top 60%"
        }
        });
        gsap.set(".home-about-inner2-h3", { y: "24vw", rotateZ:"5deg" });
        gsap.to(".home-about-inner2-h3", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        delay: 1,
        scrollTrigger: {
            trigger: ".home-about-inner2-line",
            start: "top 60%"
        }
        });
        gsap.set(".home-about-inner2-h32", { y: "24vw", rotateZ:"5deg", });
        gsap.to(".home-about-inner2-h32", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        delay: 0.5,
        scrollTrigger: {
            trigger: ".home-about-inner2-h3",
            start: "top 60%"
        }
        });
        gsap.set(".mob-gra-text-1", { y: "24vw", rotateZ:"5deg" });
        gsap.to(".mob-gra-text-1", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        delay: 0.3,
        scrollTrigger: {
            trigger: ".mob-gra-text-1",
            start: "top 60%"
        }
        });
        gsap.set(".mob-gra-text-2", { y: "24vw", rotateZ:"5deg", });
        gsap.to(".mob-gra-text-2", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        delay: 0.6,
        scrollTrigger: {
            trigger: ".mob-gra-text-1",
            start: "top 60%"
        }
        });

        gsap.set(".fixed-work", { position: "relative" });
        gsap.to(".fixed-work", {
        position: "fixed",
        top: "10%",
        ease: "Power3.easeOut",
        scrollTrigger: {
            trigger: ".fixed-work",
            start: "1%",
            scrub: true,
        }
        });
        // gsap.set(".fixed-work", {  color: "#FFFFFF" });
        // gsap.to(".fixed-work", {
        // color: "#808080",
        // ease: "Power3.easeOut",
        // scrollTrigger: {
        //     trigger: ".fixed-work",
        //     start: "25%",
        //     scrub: true
        // }
        // });
        gsap.set(".fixed-work", {opacity: 1 });
        gsap.to(".fixed-work", {
          opacity: 0,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".work-hide",
            start: 'top 150%',
            scrub: true,
          }
        });
        gsap.set(".vd-col-row", { scale: 1.5 });
        gsap.to(".vd-col-row", {
          scale: 1,
          ease: "Power3.linear",
          scrollTrigger: {
            trigger: ".home-section2",
            duration: 1,
            start: 'top 95%'
          }
        });
        gsap.set(".video-col1", { yPercent: -15 });
        gsap.to(".video-col1", {
          yPercent: 0,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".home-section2",
            start: 'top 90%',
            scrub: true,
          }
        });
        gsap.set(".video-col3", { yPercent: -30 });
        gsap.to(".video-col3", {
          yPercent: 0,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".home-section2",
            start: 'top 90%',
            scrub: true,
          }
        });
        gsap.set(".video-col2", { yPercent: 15 });
        gsap.to(".video-col2", {
          yPercent: -15,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".home-section2",
            start: 'top 90%',
            scrub: true,
          }
        });
        // gsap.set(".home-section2", {visibility: "visible" });
        // gsap.to(".home-section2", {
        //   visibility: "hidden",
        //   ease: "Power3.easeOut",
        //   scrollTrigger: {
        //     trigger: ".home-infinity",
        //     start: 'top 40%',
        //     scrub: true,
        //   }
        // });
        gsap.set(".home-what-inner2-row", {width: 0 });
        gsap.to(".home-what-inner2-row", {
          width: "100%",
          ease: "Power3.easeOut",
          duration: 1,
          scrollTrigger: {
            trigger: ".home-what-inner2",
            start: 'top 80%',
          }
        });
        gsap.set(".lottie-up", {display: "none"});
        gsap.to(".lottie-up", {
          display: "block",
          ease: "Power3.easeOut",
          duration: 1,
          scrollTrigger: {
            trigger: ".home-what-inner2",
            start: 'top 70%',
          }
        });
        gsap.set(".home-infinity", {opacity: 1 });
        gsap.to(".home-infinity", {
          opacity: 0,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".home-what-inner1",
            end: 'top 25%',
            scrub: true,
          }
        });
        gsap.set(".home-infinity", {visibility: "hidden" });
        gsap.to(".home-infinity", {
          visibility: "visible",
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".home-about",
            end: 'top 25%',
            scrub: true,
          }
        });
        gsap.set(".workanimation", { visibility: "hidden" });
        ScrollTrigger.batch(".workanimation", {
            onEnter: batch => {
                gsap.from(batch, {
                    duration: 1.5,
                    delay: 0,
                    yPercent: 50,
                    scale: 1,
                    autoAlpha: 0,
                    ease: "Power3.easeOut",
                    stagger: 0.4,
                    visibility: "visible",
                })
            },
            toggleActions: "play none none none",
            once: true,
            start: 'top 80%',
            end: 'top 40%',
            markers: false,
        });

        //Experimental
        gsap.set(".svg1", { visibility: "hidden" });
        ScrollTrigger.batch(".svg1", {
            onEnter: batch => {
                gsap.from(batch, {
                    duration: 1,
                    opacity: 1,
                    delay: 0,
                    yPercent: 100,
                    rotateZ: 5,
                    scale: 1,
                    autoAlpha: 0,
                    ease: "Power3.easeOut",
                    stagger: 0.08,
                    visibility: "visible",
                })
            },
            toggleActions: "play none none none",
            once: true,
            start: 'top 80%',
            end: 'top 40%',
            markers: false,
        });

        // gsap.set(".loadingh2 span", {translateY: "100%" });
        // gsap.to(".loadingh2 span", {
        //   translateY: "0%",
        //   delay: 0.1,
        //   duration: 1,
        //   ease: "Power3.easeIn",
        // });
        gsap.set(".loadingh2 span", { visibility: "hidden" });
        ScrollTrigger.batch(".loadingh2 span", {
            onEnter: batch => {
                gsap.from(batch, {
                    duration: 0.5,
                    delay: 0,
                    yPercent: 50,
                    scale: 1,
                    autoAlpha: 0,
                    ease: "Power3.easeOut",
                    stagger: 0.1,
                    visibility: "visible",
                })
            },
            toggleActions: "play none none none",
            once: true,
            start: 'top 80%',
            end: 'top 40%',
            markers: false,
        });


        gsap.set(".home-cover", {translateY: 0 });
        gsap.to(".home-cover", {
          translateY: "-100%",
          delay: 1.2,
          duration: 1,
          ease: "Power3.easeIn",
        });
        gsap.set(".homeparent", {height: "100vh" });
        gsap.to(".homeparent", {
          height: "auto",
          delay: 3,
        });
    }
    

    componentDidMount(){
        this.homeGsap();
    }

    render() {


        const infinityScroll = {
            mode: "scroll",
            actions: [
              {
                visibility: [0, 0.1],
                type: "stop",
                frames: [0],
              },
              {
                visibility: [0.2, 0.8],
                type: "seek",
                frames: [0, 450],
              },

            ],
        };
        var sliderSettings = {
            dots: false,
            arrows: false,
            infinite: false,
            autoplay: true,
            autoplaySpeed: 1000,
            slidesToShow: 4,
            slidesToScroll: 2,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  arrows: false,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: false
                }
              },
              {
                breakpoint: 600,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: "20px",
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
        };
        var testimonialSettings = {
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 1500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
        };



        return (

            <div className="landingparent">
                <a href="#"><img src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/whatsapp.png" className="whatsapp-landing" /></a>
                <Helmet>
                <title>Fingerprints Future | Global UI/UX Design Company</title>
                <meta name="description" content="High quality website design, UI/UX, and product design at our award-winning UI UX agency in India." />
                </Helmet>
            <div className="home-cover-out"></div>
            <div className="home-cover d-flex justify-content-center align-items-center position-fixed t-0 l-0 w-100 vh-100 min-vh-100 z-10000 bg-color-white color-black overflow-hidden visibility-hidden pointer-events-none">
                {/* <svg width="1147" height="309" viewBox="0 0 1147 309" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1548_28162)">
                    <path className="svg1 svg2 svg3" d="M89.2691 41.4495C100.924 41.4495 115.793 42.6653 129.493 43.8812L133.904 5.92308C117.02 3.49139 95.3158 0.199707 75.1893 0.199707C36.1925 0.229362 0 13.2775 0 67.1898V91.5364H59.4446V152.418H0V301.077H59.094V152.803H121.839V91.2695H59.094V73.2987C59.094 47.9735 71.5672 41.4495 89.2399 41.4495H89.2691Z" fill="white"/>
                    <path className="svg1 svg2 svg3" d="M344.577 301.079H296.729L293.107 272.907C264.568 295.77 233.195 308.018 205.065 308.018C171.297 308.018 147.957 290.047 147.957 250.043V96.9649H207.08V234.533C207.08 252.089 215.113 260.659 231.997 260.659C248.881 260.659 266.174 253.305 285.483 238.626V96.9649H344.577V301.079V301.079Z" fill="white"/>
                    <path className="svg1 svg2 svg3" d="M515.745 265.167C504.499 265.968 492.406 266.383 483.175 266.383C466.671 266.383 454.636 260.244 454.636 235.364V152.716H515.19V90.9747H454.636V29.7673H395.104V91.5382H454.636V152.419H395.104V241.058C395.104 294.94 430.098 308.018 467.898 308.018C485.57 308.018 504.47 305.171 519.367 302.71L515.745 265.167Z" fill="white"/>
                    <path className="svg1 svg2 svg3" d="M755.61 301.079H707.762L704.14 272.907C675.601 295.77 644.228 308.018 616.098 308.018C582.33 308.018 558.99 290.047 558.99 250.043V96.9649H618.113V234.533C618.113 252.089 626.147 260.659 643.031 260.659C659.915 260.659 677.207 253.305 696.516 238.626V96.9649H755.61V301.079Z" fill="white"/>
                    <path className="svg1 svg2 svg3" d="M937.271 91.2713L932.043 140.261C922.374 140.261 914.75 139.846 907.505 140.646C891.001 141.477 876.95 147.586 854.02 165.972V301.079H794.926V96.965H843.182L846.805 129.229C880.193 100.672 899.881 90.0554 924.418 90.0554C929.238 90.0554 933.269 90.4706 937.271 91.2713V91.2713Z" fill="white"/>
                    <path className="svg1 svg2 svg3" d="M1130.79 114.521C1144.87 131.276 1148.87 156.571 1145.25 194.114L1143.64 212.5H1019.38C1020.61 254.55 1030.25 264.752 1060.39 264.752C1092.55 264.752 1109.44 260.659 1129.54 255.766L1135.58 295.771C1119.11 301.909 1095.36 308.018 1061.18 308.018C980.355 308.018 958.242 279.045 958.242 194.529C958.242 116.152 989.615 89.6106 1061.18 90.0258C1091.74 90.0258 1115.87 97.3802 1130.76 114.521H1130.79ZM1019 183.527H1097.81C1098.19 165.556 1096.21 153.309 1091.39 145.984C1085.34 136.584 1075.29 133.322 1060.42 133.322C1033.08 133.322 1021.02 143.137 1019 183.527V183.527Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1548_28162">
                    <rect width="1146.51" height="307.787" fill="white" transform="translate(0 0.23114)"/>
                    </clipPath>
                    </defs>
                </svg> */}
                <h2 className="loadingh2">
                    <span className="loading loading1">The </span>
                    <span className="loading loading2">future </span>
                    <span className="loading loading3">will </span>
                    <span className="loading loading4">be </span>
                    <span className="loading loading5">the </span>
                    <span className="loading loading6">one </span>
                    <span className="loading loading7">you </span>
                    <span className="loading loading8">build</span>
                </h2>
            </div>

                <style>
                    {
                        `.inner-page-btn {
                            display: none;
                        }`
                    }
                </style>

                {/* Hero Section */}
                <div className="home-hero landing-hero" id="hero">
                    <div className="landing-hero-gra"></div>
                    
                    {/* <div className="home-hero-gra-mob d-block d-lg-none"></div> */}
                    <div className="container "> 
                    <img className="img-fluid d-none d-lg-block d-md-block landing-hero-dec" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/landing-hero-phone.png" />
                        <div className="col-lg-8  home-hero-content">
                            <h1 className="line d-none d-lg-block d-md-block">
                                <span>We innovate, strategize, </span>
                            </h1>
                            <h1 className="line d-none d-lg-block d-md-block">
                                <span>design and develop digital </span>
                            </h1>
                            <h1 className="line d-none d-lg-block d-md-block">
                                <span>products and services for </span>
                            </h1>
                            <h1 className="line d-none d-lg-block d-md-block">
                                <span>enterprises and organizations.</span>
                            </h1>
                            <div className="hh-mobile-h1 d-block d-lg-none d-md-none">
                                <div className="text-center">
                                <img className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/landing-hero-phonem.png" />
                                </div>
                                
                                <h1>We innovate, strategize, design and develop digital products and services for enterprises and organizations.</h1>
                            </div>

                            
                            <div className="extra-button-col">
                                <a href="#contact" className="extra-button">Get in touch</a>
                            </div>
                        </div>
                       
                    </div>
                </div>
                {/* Hero Section End*/}
                {/* Video Scroll Section */}
                <div className="home-section2 hide-nav">
                    {/* <div><img alt="Fingerprints Future" className="img-fluid w-100 d-block d-lg-none" src={dummy}/></div> */}
                    {/* <div className="">
                        <div className="row vd-col-row">
                            <div className="video-col1">
                                <figure className="figure1"><div><video id="1d8ba078-9805-90be-27f8-86b6ecb777ad-video" autoPlay={true} loop={true}  muted={true} playsInline={true} data-wf-ignore="true" data-object-fit="cover"><source src={v4} data-wf-ignore="true" /></video><video id="1d8ba078-9805-90be-27f8-86b6ecb777ad-video" autoPlay={true} loop={true}  muted={true} playsInline={true} data-wf-ignore="true" data-object-fit="cover"><source src={v3} data-wf-ignore="true" /></video><img alt="Fingerprints Future" className="img-fluid" src={Dummy3} /></div></figure>
                            </div>
                            <div className="video-col2">
                                <figure className="figure2"><div><img alt="Fingerprints Future" className="img-fluid" src={Dummy4} /><video id="1d8ba078-9805-90be-27f8-86b6ecb777ad-video" autoPlay={true} loop={true}  muted={true} playsInline={true} data-wf-ignore="true" data-object-fit="cover"><source src={v2} data-wf-ignore="true" /></video><img alt="Fingerprints Future" className="img-fluid" src={Dummy6} /></div></figure>
                            </div>
                            <div className="video-col3">
                                <figure className="figure3"><div><video id="1d8ba078-9805-90be-27f8-86b6ecb777ad-video" autoPlay={true} loop={true}  muted={true} playsInline={true} data-wf-ignore="true" data-object-fit="cover"><source src={v1} data-wf-ignore="true" /></video><video id="1d8ba078-9805-90be-27f8-86b6ecb777ad-video" autoPlay={true} loop={true}  muted={true} playsInline={true} data-wf-ignore="true" data-object-fit="cover"><source src={v6} data-wf-ignore="true" /></video><img alt="Fingerprints Future" className="img-fluid" src={Dummy9} /></div></figure>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="">
                        <div className="row vd-col-row">
                            <div className="video-col1">
                                <figure className="figure1"><div><img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-video-1.png" /><img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-video-2.png" /><img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-video-3.png" /></div></figure>
                            </div>
                            <div className="video-col2">
                                <figure className="figure2"><div><img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-video-2-1.png" /><img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-video-2-2.png" /><img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-video-2-3.png" /></div></figure>
                            </div>
                            <div className="video-col3">
                                <figure className="figure3"><div><img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-video-3-1.png" /><img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-video-3-2.png" /><img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-video-3-3.png" /></div></figure>
                            </div>
                        </div>
                    </div> */}
                    <video src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/futureintro3.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" poster="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/poster-video-p.png"/>
                </div>
                {/* Video Scroll Section End*/}
                <main>
                    {/* About Section */}
                    <div className="home-about ">
                        <div className="home-about-gra"></div>
                        <div className="container home-about-inner1">
                            <div className="row">
                                <div className="col-lg-1"></div>
                                <div className="col-lg-8">
                                <div className="">
                                    <h2 className="line d-none d-lg-block d-md-block">
                                        <span>Meaningful user experiences</span>
                                    </h2>
                                    <h2 className="line d-none d-lg-block d-md-block">
                                        <span>backed by great design.</span>
                                    </h2>
                                    <h2 className="line d-none d-lg-block d-md-block line-dd">
                                        <span><dd>Welcome to the Future.</dd></span>
                                    </h2>
                                    <div className="workanimation d-block d-lg-none d-md-none">
                                        <h2  >Meaningful user experiences backed by great design. <dd>Welcome to the Future.</dd></h2>
                                    </div>

                        </div>
                                    <div className="extra-button-col d-none d-lg-block workanimation">
                                        <a href="/services" className="extra-button process-link">Our capabilities</a>
                                    </div>
                                    <div className="ha-mobile-a d-block d-lg-none workanimation">
                                            {/* <a className="process-link" href="/services">Our capabilities</a> */}
                                            <div className="extra-button-col">
                                                <a href="/services" className="extra-button process-link">Our capabilities</a>
                                            </div>
                                    </div>
                                    {/* <a className="d-none d-lg-block link process-link" href="/services">Our services</a> */}
                                </div>
                                <div className="col-lg-3"></div>
                            </div>
                        </div>
                                            {/* Work Section */}
                    <div className="home-work">
                        {/* <div className="home-work-gra"></div> */}
                        <div className="fixed-work-parent"><h4 className="fixed-work">WORK</h4></div>
                        <div className="container fixed-work-cont">
                        <div className="row home-work-parent ">
                            <div className="col-lg-6  home-work-child workanimation">
                                <a className="sunnybee-link" href="/work/sunnybee"></a>
                                <img alt="Fingerprints Future" className="img-fluid d-none d-lg-block" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybeex2.png" />
                                <img alt="Fingerprints Future" className="img-fluid d-block d-lg-none" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybee.png" />
                                <h3>SunnyBee Market</h3>
                                <p>Mobile App</p>
                            </div>
                            <div className="col-lg-6 home-work-child workanimation">
                                <a className="savittr-link" href="/work/savittr"></a>
                                <img alt="Fingerprints Future" className="img-fluid d-none d-lg-block" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittrx2.png" />
                                <img alt="Fingerprints Future" className="img-fluid d-block d-lg-none" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/savittr.png" />
                                <h3>Savittr</h3>
                                <p>Wordpress Design & Development</p>
                            </div>

                            </div>
                            <div className="row home-work-parent work-hide">

{/* <div className="col-lg-6  home-work-child workanimation">
    <a className="dealsplus-website-link" href="/work/dealsplus-website"></a>
    <img alt="Fingerprints Future" className="img-fluid d-none d-lg-block" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-webx2.png" />
    <img alt="Fingerprints Future" className="img-fluid d-block d-lg-none" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-web.png" />
    <h3>Deals Plus</h3>
    <p>Website Design & Development</p>
</div> */}

<div className="col-lg-6  home-work-child workanimation">
    <a className="e-wallet-link" href="/work/e-wallet"></a>
    <img alt="Fingerprints Future" className="img-fluid d-none d-lg-block" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet2x2.png" />
    <img alt="Fingerprints Future" className="img-fluid d-block d-lg-none" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/ewallet1.png" />
    <h3>E-Wallet</h3>
    <p>Mobile App</p>
</div>


{/* <div className="col-lg-6  home-work-child workanimation">
    <a className="dealsplus-website-link" href="/work/dealsplus-website"></a>
    <img alt="Fingerprints Future" className="img-fluid d-none d-lg-block" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-webx2.png" />
    <img alt="Fingerprints Future" className="img-fluid d-block d-lg-none" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-web.png" />
    <h3>Deals Plus</h3>
    <p>Website Design & Development</p>
</div> */}

<div className="col-lg-6 home-work-child workanimation">
    <a className="dealsplus-product-link" href="/work/dealsplus-product"></a>
    <img alt="Fingerprints Future" className="img-fluid d-none d-lg-block" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-thumbx2.png" />
    <img alt="Fingerprints Future" className="img-fluid d-block d-lg-none" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/dealsplus-thumbx1.png" />
    <h3>Deals Plus</h3>
    <p>Product Design</p>
</div>

</div>
                            <div className="row home-work-parent ">

                            <div className="col-lg-6 home-work-child workanimation">
                                <a className="fut-bank-link" href="/work/fut-bank"></a>
                                <img alt="Fingerprints Future" className="img-fluid d-none d-lg-block" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/futx2.png" />
                                <img alt="Fingerprints Future" className="img-fluid d-block d-lg-none" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/fut.png" />
                                <h3>Fut Bank</h3>
                                <p>Product Design</p>
                            </div>
                            <div className="col-lg-6  home-work-child workanimation">
                                <a className="authorcafe-link" href="/work/authorcafe"></a>
                                <img alt="Fingerprints Future" className="img-fluid d-none d-lg-block" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-thumbx2.png" />
                                <img alt="Fingerprints Future" className="img-fluid d-block d-lg-none" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/authorcafe-thumbx1.png" />
                                <h3>Authorcafe</h3>
                                <p>Website Design & Development</p>
                            </div>
                            </div>


                            
                        </div>
                        {/* <a className="work-link" href="/work"><h3 className="view-all">View all work</h3></a> */}
                        <div className="extra-button-col">
                            <a href="/work" className="form-control work-link">View all work</a>
                        </div>
                    </div>
                    {/* Work Section End*/}
                        <div className="container home-about-inner2">
                            <div className="home-about-inner-inner2">
                                <div><h2 className="home-about-inner2-h2 ">We combine</h2></div>
                                <div className="home-about-inner2-line"></div>
                            </div>
                            <div className="d-none d-lg-block d-md-block">
                                <div className="home-about-inner2-h3-div"><h3 className="home-about-inner2-h3"><span>Technology</span></h3></div>
                                <div className="home-about-inner2-h32-div"><h3 className="home-about-inner2-h32"><div><span>& Creativity</span></div></h3></div>
                            </div>
                            <div className="d-block d-lg-none d-md-none">
                                <div className="home-about-inner2-h3-div"><h3 className="mob-gra-text-1"><span>Technology</span></h3></div>
                                <div className="home-about-inner2-h32-div"><h3 className="mob-gra-text-2"><div><span>& Creativity</span></div></h3></div>
                            </div>
                        </div>
                        {/* <div className="container home-infinity">
                            <Lottie
                            animationData={Infinity}
                            interactivity={infinityScroll}
                            style={{ width: "80%", height: "80%" }}
                            />
                        </div> */}
                        <InfinitySection />
                        {/* <div className="container inifinitysvg d-block d-lg-none workanimation">
                            <img className="img-fluid" src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/text-infinite-png.png" />
                        </div> */}
                    </div>
                    {/* About Section End*/}
                    {/* What We Do Section */}
                    <div className="home-what">
                        <div className="container home-what-inner1">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h2 className="line d-none d-lg-block d-md-block">
                                        <span>What we do</span>
                                    </h2>
                                    <div className="workanimation d-block d-lg-none d-md-none">
                                        <h2 className="" >What we do</h2>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6 right"><a href="/services" className="h2animation process-link">Our Services</a></div> */}
                            </div>
                        </div>
                        <div className="home-what-inner2 d-none d-lg-block">
                            <div className="row home-what-inner2-row">
                                <div className="col-lg-4 home-services">
                                    <div className="home-services-hover"></div>
                                    {/* <img alt="Fingerprints Future" src={Research} alt="research"/> */}
                                    <div className="lottie-up">
                                        <Lottie
                                            loop
                                            animationData={ResearchJson}
                                            play
                                            style={{ width: "2.778vw", height: "2.778vw" }}
                                            />
                                        <div className="row">
                                            <div className="col-6"><h3>Research</h3></div>
                                            {/* <div className="col-6 right"><img alt="Fingerprints Future"  src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/arrow.svg" /></div> */}
                                        </div>
                                    </div>
                                    <p>
                                    Digital Product Strategy <br />
                                    UX research <br />
                                    Heuristic Analysis <br />
                                    User Personas <br />
                                    Content Strategy <br />
                                    Information Architecture <br />
                                    Design Audit <br />
                                    Usability Testing <br />
                                    User Interviews <br />
                                    Wireframing <br />
                                    </p>
                                </div>
                                <div className="col-lg-4 home-services ">
                                    <div className="home-services-hover"></div>
                                    {/* <img alt="Fingerprints Future" src={Design} alt="design"/> */}
                                    <div className="design-lottie-up">
                                        <Lottie
                                            loop
                                            animationData={DesignJson}
                                            play
                                            style={{ width: "2.778vw", height: "2.778vw" }}
                                            />
                                        <div className="row">
                                            <div className="col-6"><h3>Design</h3></div>
                                            {/* <div className="col-6 right"><img alt="Fingerprints Future" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/arrow.svg" /></div> */}
                                        </div>
                                    </div>
                                    <p>
                                    Digital Branding <br />
                                    User Experience Design <br />
                                    User interface Design <br />
                                    Interaction Design <br />
                                    Digital Prototyping <br />
                                    Motion Design <br />
                                    Illustrations and Iconography <br />
                                    Design System <br />
                                    </p>
                                </div>
                                <div className="col-lg-4 home-services ">
                                    <div className="home-services-hover"></div>
                                    {/* <img alt="Fingerprints Future" src={Build} alt="build"/> */}
                                    <div className="build-lottie-up">
                                        <Lottie
                                            loop
                                            animationData={BuildJson}
                                            play
                                            style={{ width: "2.778vw", height: "2.778vw" }}
                                            />
                                        <div className="row">
                                            <div className="col-6"><h3>Build</h3></div>
                                            {/* <div className="col-6 right"><img alt="Fingerprints Future" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/arrow.svg" /></div> */}
                                        </div>
                                    </div>
                                    <p>
                                    Front-End Development <br />
                                    Web Application <br />
                                    Mobile Application <br />
                                    Custom Application <br />
                                    SAAS Implementation <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="home-what-inner2-mobile d-block d-lg-none">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item workanimation">
                                    <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <div className="row">
                                            <div className="col-6 mobile-lottie-acc">
                                            <Lottie
                                            loop
                                            animationData={ResearchJson}
                                            play
                                            style={{ width: "30px", height: "30px" }}
                                            />
                                            </div>
                                            <div className="col-6"><h3>Research</h3></div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <p>
                                    Digital Product Strategy <br />
                                    UX research <br />
                                    Heuristic Analysis <br />
                                    User Personas <br />
                                    Content Strategy <br />
                                    Information Architecture <br />
                                    Design Audit <br />
                                    Usability Testing <br />
                                    User Interviews <br />
                                    Wireframing <br />
                                    </p>
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item workanimation">
                                    <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <div className="row">
                                            <div className="col-6 mobile-lottie-acc">
                                            <Lottie
                                            loop
                                            animationData={DesignJson}
                                            play
                                            style={{ width: "30px", height: "30px" }}
                                            />
                                            </div>
                                            <div className="col-6"><h3>Design</h3></div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <p>
                                    Digital Branding <br />
                                    User Experience Design <br />
                                    User interface Design <br />
                                    Interaction Design <br />
                                    Digital Prototyping <br />
                                    Motion Design <br />
                                    Illustrations and Iconography <br />
                                    Design System <br />
                                    </p>
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item workanimation">
                                    <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <div className="row">
                                            <div className="col-6 mobile-lottie-acc">
                                            <Lottie
                                            loop
                                            animationData={BuildJson}
                                            play
                                            style={{ width: "30px", height: "30px" }}
                                            />
                                            </div>
                                            <div className="col-6"><h3>Build</h3></div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <p>
                                    Front-End Development <br />
                                    Web Application <br />
                                    Mobile Application <br />
                                    Custom Application <br />
                                    SAAS Implementation <br />
                                    </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* What We Do Section End*/}
                    {/* Landing CTA */}
                    <div className="landing-cta">
                        <div className="home-about-gra"></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <h2 className="line d-none d-lg-block d-md-block">
                                        <span className="workanimation">Let's talk about how we can </span>
                                    </h2>
                                    <h2 className="line d-none d-lg-block d-md-block">
                                        <span className="workanimation">help you reach your goals. </span>
                                    </h2>
                                    <div className="hh-mobile-h1 d-block d-lg-none d-md-none">
                                        <h2 className="workanimation" >Let's talk about how we can help you reach your goals.</h2>
                                    </div>
                                    <p className="workanimation">Schedule your free 30-minute consultation call with us today.</p>
                                </div>
                                <div className="col-lg-4 landing-button-side workanimation">
                                        <a  href="https://calendly.com/aishwaryadaswani" target="_blank" className="extra-button">Setup a meeting</a>
                                </div>
                                <div className="col-lg-1"></div>
                            </div>
                        </div>
                    </div>
                    {/* Landing CTA End*/}
                    {/* Clients Section */}
                    <div className="home-clients">
                        <div className="container">
                            <h2 className="line d-none d-lg-block d-md-block">
                                <span>We partner with people who </span>
                            </h2>
                            <h2 className="line d-none d-lg-block d-md-block">
                                <span>are excited about the Future.</span>
                            </h2>
                            {/* <h2 className="line d-none d-lg-block">
                                <span>forward<dl className="forward">&nbsp;thinking</dl></span>
                            </h2> */}
                            <div className="workanimation d-block d-lg-none d-md-none">
                                <h2 >We partner with people <br className="d-none d-lg-block"/> who are excited about <br />the Future.</h2>
                            </div>
                        </div>
                        <div className="home-clients-c">
                            {/* Carousel */}
                            <Slider {...sliderSettings} className="home-clients-c-c">
                                <div className="home-clients-inner ">
                                    <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/client1x2.png" />
                                    <p>Redefined banking for a fintech leader by designing Intellect’s web and mobile applications so users could manage their own bank accounts.</p>
                                </div>
                                <div className="home-clients-inner ">
                                    <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/client2x2.png" />
                                    <p>Gave a fresh new identity to  TNQ, a technology services company and revamped it’s website and communication. </p>
                                </div>
                                <div className="home-clients-inner ">
                                    <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/client3x2.png" />
                                    <p>Enhanced the e-commerce experience of SunnyBee by using data-driven product design methodologies.</p>
                                </div>
                                <div className="home-clients-inner ">
                                    <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/client4x2.png" />
                                    <p>Positioned Vinveli, a global technology company in the aerospace and defence space, as a leader with an intelligently designed, informative website.</p>
                                </div>
                                <div className="home-clients-inner ">
                                    <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/client5x2.png" />
                                    <p>Understanding what fund managers want. Using insights from user interviews, we helped deals plus evolve the UX of their Product and also improved marketing activities by giving a fresh new look for their website.</p>
                                </div>
                                <div className="home-clients-inner ">
                                    <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/client6x2.png" />
                                    <p>Redesigned the website to enhance credibility and support marketing efforts for the product.</p>
                                </div>
                                <div className="home-clients-inner ">
                                    <img alt="Fingerprints Future" className="img-fluid" src="https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/client7x2.png" />
                                    <p>Created a modern and visually appealing website with improved navigation and fluid animations, resulting in increased leads and a clear call to action.</p>
                                </div>
                            </Slider>
                            <div className="testimonials">
                                <Slider {...testimonialSettings}>
                                    <div className="home-testimonials-inner">
                                        <h3>We worked with Fingerprints for a year developing an e-commerce mobile app and website for SunnyBee. They were committed to creating the best user experience, and we're thrilled with the final result. Fingerprints' deep dive into the category, competitor analysis, and design system were invaluable. We're confident the app will be a success.</h3>
                                        <h4>Sriram Sridharan</h4>
                                        <h5>WayCool Foods and Products</h5>
                                    </div>
                                    <div className="home-testimonials-inner">
                                        <h3>We partnered with Fingerprints for two months to make our website mobile-friendly and showcase our range of products on the app. Their team helped us position ourselves as a tech-first company and build brand awareness. We worked closely together to achieve our goals, and every tweak and modification brought us closer to success. We're thrilled to have a committed partner in Fingerprints.</h3>
                                        <h4>Vikram</h4>
                                        <h5>GBR Metals</h5>
                                    </div>
                                    <div className="home-testimonials-inner">
                                        <h3>Fingerprints helped us make our website mobile-friendly and develop an app to showcase our product range. Their team positioned us as a tech-first company and helped build brand awareness. Together, we achieved our goals with every modification bringing us closer to success. It was a pleasure working with Fingerprints, and we're excited to continue our partnership.</h3>
                                        <h4>Vishal Bysani</h4>
                                        <h5>Viveks </h5>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>

                    </main>
                    {/* Clients Section End*/}

                <LFooter/>
            </div>
         );
    }
    
}
 
export default Landing;
