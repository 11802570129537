import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from '../pages/home';
import Process from '../pages/process'
import Sunnybee from '../projects/sunnybee'
import Savittr from '../projects/savittr'
import DealsplusW from '../projects/dealsplusW';
import DealsplusP from '../projects/dealsplusP';
import Futbank from '../projects/futbank';
import Drapesanddreams from '../projects/drapesanddreams';
import Work from '../pages/work';
import NotFound from '../pages/404';
import Thankyou from '../pages/thankyou';
import Privacy from '../pages/privacy';
import BlogTemp from '../blogs/blog-temp';
import BlogLanding from '../blogs/blog-landing';
import Authorcafe from "../projects/authorcafe";
import Mahasaghar from "../projects/mahasaghar";
import Ocacademy from "../projects/ocacademy";

import Contact from "../pages/contact";
import BlogDesignsystem from "../blogs/blog-designsystem";
import BlogDesigningforusability from "../blogs/blog-designingforusability";
import BlogResearchandproductdesign from "../blogs/blog-researchandproductdesign";
import BlogSimplemodel from "../blogs/blog-simplemodel";
import CareerLanding from "../careers/career-landing";
import EWallet from "../projects/e-wallet";
import UiuxDesigner from "../careers/uiux-designer";
import Landing from "../pages/landing";
import CryptoWallet from "../projects/cryptowallet";
import Flyet from "../projects/flyet";
import Tnq from "../projects/tnq";
import BlogChatBot from "../blogs/blog-chatbots";
import AIdumbornot from "../blogs/blog-ai-dumb-or-not";
import Healthcare from "../projects/heathcare";
import UsingHeuristicsUiUxDesign from "../blogs/blog-using-heuristics-ui-ux-design";
import Blogdiscoveringthemagicofspatialinput from "../blogs/blog-discoveringthemagicofspatialinput";
import Eleveight from "../projects/eleveight";
import XTM from "../projects/xtm";
import NewblogDesignsystem from "../new-blogs/newblog-designsystem";
import NewblogAiDumb from "../new-blogs/newblog-ai-dumb";
import NewblogDesigningforusability from "../new-blogs/newblog-designingforusability";
import NewblogChatbots from "../new-blogs/newblog-chatbots";
import NewblogDiscoveringthemagicofspatialinput from "../new-blogs/newblog-discoveringthemagicofspatialinput";
import NewblogResearchandproductdesign from "../new-blogs/newblog-researchandproductdesign";
import NewblogSimplemodel from "../new-blogs/newblog-simplemodel";
import NewblogUsingHeuristicsUiUxDesign from "../new-blogs/newblog-using-heuristics-ui-ux-design";

// import CommingSoon from "../pages/comingsoon";
function AnimatedRoutes() {
  const location = useLocation();
  return (
      <Routes key={location.pathname} location={location}>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/services' element={<Process />} />
        <Route exact path='/work' element={<Work />} />
        <Route exact path='/contact' element={<Contact />} />

        <Route exact path='/work/sunnybee' element={<Sunnybee />} />
        <Route exact path='/work/savittr' element={<Savittr />} />
        <Route exact path='/work/dealsplus-website' element={<DealsplusW />} />
        <Route exact path='/work/dealsplus-product' element={<DealsplusP />} />
        <Route exact path='/work/fut-bank' element={<Futbank />} />
        {/* <Route exact path='/work/drapesanddreams' element={<Drapesanddreams />} /> */}
        <Route exact path='/work/authorcafe' element={<Authorcafe />} />
        <Route exact path='/work/e-wallet' element={<EWallet />} />
        <Route exact path='/work/flyet' element={<Flyet />} />
        <Route exact path='/work/crypto-wallet' element={<CryptoWallet />} />
        <Route exact path='/work/tnq' element={<Tnq />} />
        <Route exact path='/work/healthcare' element={<Healthcare />} />
        <Route exact path='/work/eleveight' element={<Eleveight />} />
        <Route exact path='/work/xtm' element={<XTM />} />
        <Route exact path='/work/mahasaghar' element={<Mahasaghar />} />
        <Route exact path='/work/oc-academy' element={<Ocacademy />} />

        <Route path='/thankyou' element={<Thankyou />} />
        {/* <Route path='/form' element={<TypeformEmbed />} /> */}
        <Route path='/privacy' element={<Privacy />} />
        <Route path='*' element={<NotFound />} />
        {/* <Route path='/ui-ux-design-agency' element={<Landing />} /> */}
        
        <Route exact path='/blog' element={<BlogLanding />} />
        
        <Route path='/blog/design-systems' element={<NewblogDesignsystem />} />
        <Route path='/blog/ai-dumb-or-not' element={<NewblogAiDumb />} />
        <Route path='/blog/chatbots-hot-or-not' element={<NewblogChatbots />} />
        <Route path='/blog/the-importance-of-designing-for-usability' element={<NewblogDesigningforusability />} />
        <Route path='/blog/discovering-the-magic-of-spatial-input' element={<NewblogDiscoveringthemagicofspatialinput />} />
        <Route path='/blog/the-role-of-resarch-and-product-design' element={<NewblogResearchandproductdesign />} />
        <Route path='/blog/why-gamification-works-in-digital-product-design' element={<NewblogSimplemodel />} />
        <Route path='/blog/using-heuristics-ui-ux-design' element={<NewblogUsingHeuristicsUiUxDesign />} />

        {/* <Route path='/blog-temp' element={<BlogTemp />} /> */}
        {/* <Route path='/blog/design-systems' element={<BlogDesignsystem />} />
        <Route path='/blog/the-importance-of-designing-for-usability' element={<BlogDesigningforusability />} />
        <Route path='/blog/the-role-of-resarch-and-product-design' element={<BlogResearchandproductdesign />} />
        <Route path='/blog/why-gamification-works-in-digital-product-design' element={<BlogSimplemodel />} />
        <Route path='/blog/chatbots-hot-or-not' element={<BlogChatBot />} />
        <Route path='/blog/ai-dumb-or-not' element={<AIdumbornot />} />
        <Route path='/blog/using-heuristics-ui-ux-design' element={<UsingHeuristicsUiUxDesign />} />
        <Route path='/blog/discovering-the-magic-of-spatial-input' element={<Blogdiscoveringthemagicofspatialinput />} /> */}

        {/* <Route path='/careers' element={<CareerLanding />} /> */}
        {/* <Route path='/careers/ui-ux-designer' element={<UiuxDesigner />} /> */}
        
      </Routes>
  );
}

export default AnimatedRoutes;
